// @flow
// $FlowFixMe
import {Validation} from '@wellstone-solutions/common';

const {isOptional, isRequired} = Validation;

export const schema = {
  content: isRequired,
  id: isOptional,
  scheduled: isRequired,
  status: isRequired,
  title: isRequired,
  author: isRequired,
};

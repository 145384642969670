// @flow
import type {ComponentType, Node} from 'react';
import {Link} from 'react-router-dom';
import {observer} from 'mobx-react';
// $FlowFixMe
import {DataGrid, Paper, Typography} from '@wellstone-solutions/web';
// $FlowFixMe
import type {GridColDef} from '@mui/x-data-grid';
import {useStores} from 'hooks/useStores';
import {DATE, DATE_TIME} from 'constants/dateFormats';
import {formatDate} from 'utils/date';

export const List: ComponentType<{}> = observer((): Node => {
  const {resourceStore} = useStores();

  const columns: GridColDef[] = [
    {field: 'id', hide: true, headerName: ''},
    {
      field: 'title',
      headerName: 'Title',
      flex: 4,
      renderCell: (params) => <Link to={params.id}>{params.value}</Link>,
    },
    {
      field: 'subtitle',
      headerName: 'Subtitle',
      hide: true,
    },
    {
      field: 'contactSearch',
      headerName: 'Contacts',
      hide: true,
    },
    {
      field: 'organizationTitle',
      headerName: 'Organization Title',
      hide: true,
    },
    {
      field: 'expires',
      headerName: 'Expires',
      flex: 1,
      renderCell: (params) => formatDate(params.value, DATE),
    },
    {
      field: 'modified',
      headerName: 'Modified',
      flex: 1,
      renderCell: (params) => formatDate(params.value, DATE_TIME),
    },
  ];

  return (
    <Paper
      sx={{
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        height: 'auto',
      }}>
      <Typography variant="h5" sx={{mb: 4}}>
        Resource Answers
      </Typography>
      <DataGrid
        autoHeight
        rows={Array.from(resourceStore.dataGridAnswers)}
        columns={columns}
        isFilterable
      />
    </Paper>
  );
});

// @flow
import React from 'react';
import type {Node} from 'react';
// $FlowFixMe
import {Stack} from '@wellstone-solutions/web';
import {useStores} from 'hooks/useStores';
import {BackButton} from 'components/BackButton';
import {Form} from './Form';
import {QuestionModel} from '../../models/question';

export const New = (): Node => {
  const {resourceStore} = useStores();

  return (
    <>
      <Stack sx={{mb: 2}} direction="row">
        <BackButton path="../../questions" />
      </Stack>
      <Form
        isNew={true}
        question={QuestionModel.FormFactory()}
        onSave={(question) => resourceStore.createQuestion(question)}
      />
    </>
  );
};

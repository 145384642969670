// @flow
import {computed, makeObservable, observable, runInAction} from 'mobx';
import type {IObservableArray} from 'mobx';
import type {ApiResponseType} from '@wellstone-solutions/common';
import {RootStore} from '../../RootStore';
import {AnswerModel} from './models/answer';
import {CategoryModel} from './models/category';
import {PhaseModel} from './models/phase';
import {QuestionModel} from './models/question';
import type {
  APIAnswerType,
  APIResourceQuestionType,
  FormResourceQuestionType,
  UIResourceQuestionType,
  UIAnswerType,
  UICategoryType,
  UIPhaseType,
} from './types';

export class ResourceStore {
  questionCategories: IObservableArray<UICategoryType> = observable.array();
  questionPhases: IObservableArray<UIPhaseType> = observable.array();
  questions: IObservableArray<UIResourceQuestionType> = observable.array();
  answers: IObservableArray<UIAnswerType> = observable.array();
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      dataGridAnswers: computed,
      dataGridQuestions: computed,
      questionCategories: observable,
      questionPhases: observable,
      questions: observable,
      answers: observable,
    });

    this.rootStore = rootStore;
  }

  async init(): Promise<mixed> {
    return Promise.all([
      QuestionModel.getAll(),
      PhaseModel.getAll(),
      CategoryModel.getAll(),
      AnswerModel.getAll(),
    ]).then(([questions, phases, categories, answers]) => {
      runInAction(() => {
        this.questionPhases.replace(phases);
        this.questionCategories.replace(categories);
      });

      const uiQuestions = questions
        ? questions.map((question: APIResourceQuestionType) =>
            QuestionModel.toUI(
              question,
              this.questionPhases,
              this.questionCategories,
            ),
          )
        : [];

      runInAction(() => {
        this.questions.replace(uiQuestions);
      });

      const uiAnswers = answers
        ? answers.map((answer) => AnswerModel.toUI(answer, this.questions))
        : [];

      runInAction(() => {
        this.answers.replace(uiAnswers);
      });
    });
  }

  get dataGridQuestions(): Array<mixed> {
    const location = this.rootStore.resourcesUIStore
      ? this.rootStore.resourcesUIStore.location
      : 'Unknown';

    return this.questions.map((question) =>
      QuestionModel.toDataGrid(question, this.answers, location),
    );
  }

  get dataGridAnswers(): Array<mixed> {
    return this.answers.map((answer) => AnswerModel.toDataGrid(answer));
  }

  async createQuestion(
    question: FormResourceQuestionType | UIResourceQuestionType,
  ): Promise<ApiResponseType<APIResourceQuestionType>> {
    const payload = QuestionModel.toAPI(question);

    const response = await QuestionModel.create(payload);

    if (response.isSuccess) {
      runInAction(() => {
        this.questions.push(
          QuestionModel.toUI(
            response.data,
            this.questionPhases,
            this.questionCategories,
          ),
        );
      });
    }

    return response;
  }

  async updateQuestion(
    question: FormResourceQuestionType | UIResourceQuestionType,
  ): Promise<ApiResponseType<APIResourceQuestionType>> {
    const payload = QuestionModel.toAPI(question);
    const response = await QuestionModel.update(payload);

    if (response.isSuccess) {
      runInAction(() => {
        const observable = this.questions.find((q) => q.id === question.id);

        Object.assign(
          observable,
          QuestionModel.toUI(
            response.data,
            this.questionPhases,
            this.questionCategories,
          ),
        );
      });
    }

    return response;
  }

  async createAnswer(
    answer: UIAnswerType,
  ): Promise<ApiResponseType<APIAnswerType>> {
    const payload = AnswerModel.toAPI(answer);
    const response = await AnswerModel.create(payload);

    if (response.isSuccess) {
      runInAction(() => {
        this.answers.push(AnswerModel.toUI(response.data, this.questions));
      });
    }

    return response;
  }

  async removeAnswer(id: string): Promise<ApiResponseType<void>> {
    const response = await AnswerModel.remove(id);

    if (response.isSuccess) {
      runInAction(() => {
        this.answers.replace(this.answers.filter((answer) => answer.id !== id));
      });
    }

    return response;
  }

  async updateAnswer(
    answer: UIAnswerType,
  ): Promise<ApiResponseType<APIAnswerType>> {
    const payload = AnswerModel.toAPI(answer);
    const response = await AnswerModel.update(payload);

    if (response.isSuccess) {
      runInAction(() => {
        const observable = this.answers.find((a) => a.id === answer.id);

        Object.assign(
          observable,
          AnswerModel.toUI(response.data, this.questions),
        );
      });
    }

    return response;
  }
}

// @flow
import type {Node} from 'react';

import {flattenFlags} from '../../utils';
import {AddFlag} from './AddFlag';
import {Theme} from '@wellstone-solutions/common';
import {
  BasicModal,
  DataGrid,
  List,
  ListItem,
  Paper,
  Typography,
  Box,
} from '@wellstone-solutions/web';

import {IconButton} from 'components/IconButton';
import {useStores} from 'hooks/useStores';
import {observer} from 'mobx-react';
import {useState} from 'react';

export const FeatureFlags: Node = observer((): Node => {
  const [openModel, setOpenModal] = useState(false);
  const {customerServiceStore, alertStore} = useStores();

  const featureFlagTableRows = flattenFlags(customerServiceStore.featureFlags);

  const handleRemoveFlag = async (row) => {
    const isConfirmed = window.confirm(
      `Are you sure you want to delete "${row.ownerName}"?`,
    );

    if (!isConfirmed) {
      return;
    }

    const res = await customerServiceStore.removeOwnerFromFeatureFlag(row);
    if (!res.isSuccess) {
      alertStore.addError('Something went wrong');
      return;
    }
    alertStore.addSuccess(`Deleted "${row.ownerName}"`);
  };

  const renderRemoveCell = ({row}) => (
    <IconButton
      onClick={() => handleRemoveFlag(row)}
      iconColor={Theme.colorPalette.red}
      iconName="Trash"
      variant="text"
    />
  );

  const renderOwnerCell = ({row}) => (
    <List sx={{}}>
      <ListItem key={row.id} disablePadding>
        <Box style={{margin: '.5rem'}}>
          <Typography variant="body2">{row.ownerName}</Typography>
          {row.email && (
            <Typography variant="body2" color={Theme.colorPalette.dark}>
              {row.email}
            </Typography>
          )}
          {row.orgName && (
            <Typography variant="body2" color={Theme.colorPalette.dark}>
              Org: {row.orgName}
            </Typography>
          )}
        </Box>
      </ListItem>
    </List>
  );

  const renderFlagCell = ({row}) => (
    <Box sx={{padding: '1rem'}}>
      <Typography variant="subtitle2" sx={{fontWeight: 'bold'}}>
        {row.flag}
      </Typography>
      <Typography variant="body2">{row.desc}</Typography>
    </Box>
  );

  const generateColumns = () => [
    {
      field: 'flag',
      headerName: 'Flag',
      width: 200,
      flex: 1,
      renderCell: renderFlagCell,
    },
    {
      field: 'strategy',
      headerName: 'Strategy',
      width: 150,
    },
    {
      field: 'name',
      headerName: 'Owner',
      width: 200,
      flex: 1,
      renderCell: renderOwnerCell,
    },
    {
      field: 'id',
      headerName: 'Remove',
      renderCell: renderRemoveCell,
      width: 200,
      filterable: false,
    },
    {
      field: 'email',
      headerName: 'Email',
      hide: true,
    },
  ];

  const columns = generateColumns();

  return (
    <>
      <BasicModal
        open={openModel}
        onClose={() => setOpenModal(false)}
        handleOpen={() => setOpenModal(true)}
        buttonLabel="Add Flag">
        <AddFlag handleClose={() => setOpenModal(false)} />
      </BasicModal>
      <Paper
        sx={{
          p: 2,
        }}>
        <Typography
          variant="h5"
          sx={{
            margin: 1,
          }}>
          Feature Flags
        </Typography>
        <Box
          sx={{
            my: 2,
            display: 'flex',
            justifyContent: 'flex-end',
          }}>
          <IconButton onClick={() => setOpenModal(true)}>Add Flag</IconButton>
        </Box>
        <DataGrid
          autoHeight
          columns={columns}
          getRowHeight={() => 'auto'}
          initialColumnFilter="flag"
          isFilterable
          isPersisted
          rows={featureFlagTableRows.slice()}
        />
      </Paper>
      <Box
        sx={{
          my: 2,
          display: 'flex',
          justifyContent: 'flex-end',
        }}>
        <IconButton onClick={() => setOpenModal(true)}>Add Flag</IconButton>
      </Box>
    </>
  );
});

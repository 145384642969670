// @flow
import {action, computed, makeObservable, observable, runInAction} from 'mobx';
import type {IObservableArray} from 'mobx';
import moment from 'moment';
import type {LabelValueType, NameIdType} from 'types';
import type {
  ApiOptionsType,
  ApiResponseType,
} from '@wellstone-solutions/common';
import {storage} from 'utils/storage';
import {RootStore} from 'RootStore';
import {states} from 'constants/states';
import {AnnouncementModel} from './models/announcement';
import {OrganizationModel} from './models/organization';
import type {
  AdjacentAnnouncementsDataGridType,
  AnnouncementsCalendarType,
  AnnouncementsDataGridType,
  APIAnnouncementType,
  FetchAnnouncementsParamsType,
  UIAnnouncementType,
} from './types';
import {
  adjacentAnnouncementsDays,
  recipientTypes,
  status,
  storageKeys,
} from './constants';

export class AnnouncementStore {
  announcements: IObservableArray<UIAnnouncementType> = observable.array();
  adjacentAnnouncements: IObservableArray<UIAnnouncementType> =
    observable.array();
  organizations: IObservableArray<NameIdType> = observable.array();
  recipientType: string =
    storage.getItem(storageKeys.recipientType) ||
    recipientTypes.byOrganization.value;
  recipient: string = storage.getItem(storageKeys.recipient) || '';
  recipients: IObservableArray<LabelValueType> = observable.array(
    storage.getItem(storageKeys.recipients) || [],
  );
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      adjacentAnnouncements: observable,
      announcements: observable,
      cancelAnnouncement: action,
      calendarAnnouncements: computed,
      dataGridAnnouncements: computed,
      dataGridAdjacentAnnouncements: computed,
      isRecipientAllUsers: computed,
      isRecipientOrganization: computed,
      isRecipientRegion: computed,
      organizations: observable,
      recipientName: computed,
      recipientType: observable,
      recipient: observable,
      recipients: observable,
      setRecipient: action,
      setRecipients: action,
      setRecipientType: action,
      setAdjacentAnnouncements: action,
    });

    this.rootStore = rootStore;
  }

  async init(): Promise<void> {
    const organizations = await OrganizationModel.getAll();

    runInAction(() => {
      this.organizations.replace(organizations);
      this.setRecipients(this.recipientType);
      this.getAnnouncements();
    });
  }

  get calendarAnnouncements(): Array<AnnouncementsCalendarType> {
    return this.announcements.map((announcement: UIAnnouncementType) =>
      AnnouncementModel.toCalendarEvent(announcement),
    );
  }

  get dataGridAnnouncements(): Array<AnnouncementsDataGridType> {
    return this.announcements.map((announcement: UIAnnouncementType) =>
      AnnouncementModel.toDataGrid(announcement),
    );
  }

  get dataGridAdjacentAnnouncements(): Array<AdjacentAnnouncementsDataGridType> {
    return this.adjacentAnnouncements.map((announcement: UIAnnouncementType) =>
      AnnouncementModel.toAdjacentDataGrid(announcement),
    );
  }

  get isRecipientAllUsers(): boolean {
    return this.recipientType === recipientTypes.allUsers.value;
  }

  get isRecipientOrganization(): boolean {
    return this.recipientType === recipientTypes.byOrganization.value;
  }

  get isRecipientRegion(): boolean {
    return this.recipientType === recipientTypes.byRegion.value;
  }

  get recipientName(): string {
    const match = this.recipients.find(({value}) => value === this.recipient);

    return match ? match.label : recipientTypes.allUsers.label;
  }

  async cancelAnnouncement(
    announcement: UIAnnouncementType,
  ): Promise<ApiResponseType<APIAnnouncementType>> {
    const payload = AnnouncementModel.toAPI(announcement);
    let response = null;

    if (AnnouncementModel.isPublished(announcement)) {
      response = await AnnouncementModel.unpublish(String(announcement.id));
    } else {
      response = await AnnouncementModel.cancel(payload);
    }

    if (response && response.isSuccess) {
      runInAction(() => {
        // When we have a draft w/no publish date, we can do a hard delete
        if (AnnouncementModel.canHardDelete(announcement)) {
          this.announcements.replace(
            this.announcements.filter((f) => f.id !== announcement.id),
          );
        } else {
          const observable = this.announcements.find(
            (q) => q.id === announcement.id,
          );

          const nextStatus = AnnouncementModel.isPublished(announcement)
            ? status.draft
            : status.deleted;

          Object.assign(observable, {status: nextStatus});
        }
      });
    }

    return response;
  }

  async createAnnouncement(
    announcement: UIAnnouncementType,
  ): Promise<ApiResponseType<APIAnnouncementType>> {
    const payload = AnnouncementModel.toAPI(announcement);

    const response = await AnnouncementModel.create(payload);

    if (response.isSuccess) {
      runInAction(() => {
        const newAnnouncement = AnnouncementModel.toUI(
          response.data,
          Array.from(this.organizations),
        );
        this.announcements.push(newAnnouncement);
      });
    }

    return response;
  }

  async updateAnnouncement(
    announcement: UIAnnouncementType,
  ): Promise<ApiResponseType<APIAnnouncementType>> {
    const payload = AnnouncementModel.toAPI(announcement);
    const response = await AnnouncementModel.update(payload);

    if (response.isSuccess) {
      runInAction(() => {
        const observable = this.announcements.find(
          (q) => q.id === announcement.id,
        );

        Object.assign(
          observable,
          AnnouncementModel.toUI(response.data, Array.from(this.organizations)),
        );
      });
    }

    return response;
  }

  async publishAnnouncement(
    id: string,
  ): Promise<ApiResponseType<APIAnnouncementType>> {
    const response = await AnnouncementModel.publish(id);

    const announcement = this.announcements.find(
      (announcement) => announcement.id === id,
    );
    runInAction(() => {
      announcement.status = status.published;
    });

    return response;
  }

  async unpublishAnnouncement(
    id: string,
  ): Promise<ApiResponseType<APIAnnouncementType>> {
    const response = await AnnouncementModel.unpublish(id);

    const announcement = this.announcements.find(
      (announcement) => announcement.id === id,
    );
    runInAction(() => {
      announcement.status = status.draft;
    });

    return response;
  }

  async setAdjacentAnnouncements(
    scheduled: string | empty,
    excludeId?: string,
  ): Promise<void> {
    if (!scheduled || scheduled?.length === 0) {
      runInAction(() => {
        this.adjacentAnnouncements.replace([]);
      });
    } else {
      const options: ApiOptionsType<mixed, FetchAnnouncementsParamsType> = {
        params: {
          scheduled_range_start: moment(scheduled)
            .utc()
            .subtract(adjacentAnnouncementsDays, 'd')
            .toISOString(),
          scheduled_range_end: moment(scheduled)
            .utc()
            .add(adjacentAnnouncementsDays, 'd')
            .toISOString(),
        },
      };

      if (options.params) {
        if (this.isRecipientAllUsers) {
          options.params.universal = 1;
        } else if (this.isRecipientOrganization) {
          options.params.org_id = this.recipient;
        } else {
          options.params.state = this.recipient;
        }
      }

      const apiAdjacentAnnouncements = await AnnouncementModel.getAll(options);

      const uiAdjAnnouncements = apiAdjacentAnnouncements
        ? apiAdjacentAnnouncements
            .filter((announcement) => announcement.id !== excludeId)
            .map((announcement) =>
              AnnouncementModel.toUI(
                announcement,
                Array.from(this.organizations),
              ),
            )
        : [];

      runInAction(() => {
        this.adjacentAnnouncements.replace(uiAdjAnnouncements);
      });
    }
  }

  async getAnnouncements(): Promise<void> {
    this.rootStore.announcementUIStore.setIsLoading(true);
    const options: ApiOptionsType<mixed, FetchAnnouncementsParamsType> = {};

    options.params = {
      limit: 100_000,
      offset: 0,
      status: [status.draft, status.published, status.deleted],
    };

    if (this.isRecipientAllUsers) {
      options.params.universal = 1;
    } else if (this.isRecipientOrganization) {
      options.params.org_id = this.recipient;
      options.params.universal = 0;
    } else {
      options.params.state = this.recipient;
      options.params.universal = 0;
    }

    const apiAnnouncements = await AnnouncementModel.getAll(options);
    const uiAnnouncements = apiAnnouncements
      ? apiAnnouncements.map((announcement) =>
          AnnouncementModel.toUI(announcement, Array.from(this.organizations)),
        )
      : [];

    runInAction(() => {
      this.announcements.replace(uiAnnouncements);
      this.rootStore.announcementUIStore.setIsLoading(false);
    });
  }

  setRecipient(recipient: string): void {
    this.recipient = recipient;
    storage.setItem(storageKeys.recipient, recipient);
  }

  setRecipientType(recipientType: string): void {
    this.recipientType = recipientType;
    storage.setItem(storageKeys.recipientType, recipientType);

    this.setRecipients(recipientType);

    // When we change recipient type, such as from "By Region" to "All Users" or vice versa
    // when we are changing to an option that is not "All users"
    // we want to just default the recipient to the first one in the list vs
    // forcing the user to select one.  We could potentially improve this by saving off
    // recipient by recipient type but this works for now.
    if (
      !this.isRecipientAllUsers &&
      this.recipients &&
      this.recipients.length > 0
    ) {
      // $FlowIgnoreMe
      this.setRecipient(this.recipients[0].value);
      this.getAnnouncements();
    }
  }

  setRecipients(recipientType: string): void {
    const recipients =
      recipientType === recipientTypes.allUsers.value
        ? []
        : recipientType === recipientTypes.byOrganization.value
        ? this.rootStore.announcementStore.organizations.map(({id, name}) => ({
            label: name,
            value: id,
          }))
        : states.map(({label, value}) => ({
            label,
            value,
          }));

    this.recipients.replace(recipients);
    storage.setItem(storageKeys.recipients, recipients);
  }
}

// @flow
import {action, computed, makeObservable, observable, runInAction} from 'mobx';
// $FlowFixMe
import {Navigate} from 'react-router-dom';
import {Api, Crypto} from '@wellstone-solutions/common';
import {storage} from 'utils/storage';
import {routes} from 'api';
import type {ApiResponseType} from '@wellstone-solutions/common';
import {RootStore} from 'RootStore';
import {MeModel} from './models/me';
import type {UIMeType, UIMembershipType, UIOrganizationType} from './types';

const AUTH_STORAGE_KEY = 'session_information';
const APP_CRYPTO_KEY = process.env.REACT_APP_CRYPTO_KEY || '';

export class AuthStore {
  email: string = '';
  me: UIMeType | null = null;
  rootStore: RootStore | null = null;
  token: string = '';

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      clearAuth: action,
      email: observable,
      me: observable,
      hasEmail: computed,
      isAuthenticated: computed,
      memberships: computed,
      organizations: computed,
      sendNewCode: action,
      setAuth: action,
      states: computed,
      token: observable,
    });

    this.rootStore = rootStore;
  }

  get isAuthenticated(): boolean {
    return this.token.length > 0;
  }

  get hasEmail(): boolean {
    return this.email.length > 0;
  }

  get memberships(): UIMembershipType[] {
    if (!this.me) {
      return [];
    }

    return this.me.memberships.filter(
      (membership) => membership.status === 'active',
    );
  }

  get organizations(): UIOrganizationType[] {
    return this.memberships.map(({organization}) => organization);
  }

  get states(): string[] {
    return [
      ...new Set(this.memberships.map(({organization}) => organization.state)),
    ];
  }

  clearAuth() {
    this.email = '';
    this.me = null;
    this.token = '';

    Api.Instance.current().setBearerToken(null);
    storage.removeItem(AUTH_STORAGE_KEY);
  }

  async init() {
    const rawAuth = storage.getItem(AUTH_STORAGE_KEY);

    if (rawAuth) {
      const auth = Crypto.decrypt(rawAuth, APP_CRYPTO_KEY);

      if (auth) {
        this.setAuth(auth);

        const response = await Api.Instance.current().get(routes.me);

        if (response.isSuccess) {
          runInAction(() => {
            this.me = MeModel.toUI(response.data);
          });
        } else {
          this.clearAuth();
          Navigate('/login', {
            replace: true,
          });
        }
      }
    }
  }

  async requestCode(email: string): Promise<void> {
    const body = {
      email: email.toLowerCase(),
    };
    const options = {auth: false};

    await Api.Instance.current().post(routes.login, body, options);

    runInAction(() => {
      this.email = email;
    });
  }

  sendNewCode() {
    this.email = '';
  }

  setAuth(token: string) {
    this.token = token;
    Api.Instance.current().setBearerToken(token);
  }

  async signIn(code: string): Promise<ApiResponseType<{}>> {
    const body = {
      code,
      email: this.email,
    };

    const response = await Api.Instance.current().post(
      routes.validateCode,
      body,
      {
        auth: false,
        handleErrors: false,
      },
    );

    if (response.isSuccess) {
      const {token} = response.data.secrets;

      runInAction(() => {
        this.me = response.data.me;
      });

      this.setAuth(token);
      const secure = Crypto.encrypt(token, APP_CRYPTO_KEY);
      storage.setItem(AUTH_STORAGE_KEY, secure);
    }

    return response;
  }

  async signOut(): Promise<void> {
    try {
      await Api.Instance.current().delete(routes.logout);
    } finally {
      this.clearAuth();
      window.location.reload();
    }
  }
}

// @flow
import React from 'react';
import type {Node} from 'react';
// $FlowFixMe
import {Theme} from '@wellstone-solutions/common';
// $FlowFixMe
import {Box, CssBaseline} from '@wellstone-solutions/web';

type PropsType = {
  children: Node,
};

export const LayoutBasic = ({children}: PropsType): Node => {
  return (
    <Box
      component="main"
      maxWidth="xs"
      sx={{
        alignItems: 'center',
        backgroundColor: Theme.colorPalette.seafoamLight,
        display: 'flex',
        justifyContent: 'center',
        height: '100vh',
        width: '100%',
      }}>
      <CssBaseline />
      {children}
    </Box>
  );
};

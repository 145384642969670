// @flow
import type {ComponentType} from 'react';
import {styled} from '@mui/material/styles';
// $FlowFixMe
import {Theme} from '@wellstone-solutions/common';
import {
  Select,
  // $FlowFixMe
} from '@wellstone-solutions/web';

export const ToolbarSelect: ComponentType<{}> = styled(Select)(() => ({
  color: Theme.colorPalette.lightest,
  fieldset: {borderWidth: 0},
  svg: {fill: Theme.colorPalette.lightest},
}));

// @flow
import type {Node} from 'react';
import {Navigate} from 'react-router-dom';
import {Root as AnnouncementsRoot} from 'modules/announcements';
import {Root as ResourcesRoot} from 'modules/resources';
import {Root as StoriesRoot} from 'modules/stories';
import {NotFound} from 'components/NotFound';

export const contentManagement = [
  {
    Component: AnnouncementsRoot,
    isProtected: true,
    label: 'Announcements',
    path: 'announcements/*',
  },
  {
    Component: ResourcesRoot,
    isProtected: true,
    label: 'Resources',
    path: 'resources/*',
  },
  {
    Component: StoriesRoot,
    isProtected: true,
    label: 'Stories',
    path: 'stories/*',
  },
  {
    Component: (): Node => <Navigate to="./resources/questions" />,
    isProtected: true,
    index: true,
  },
  {
    Component: NotFound,
    isProtected: true,
    path: '*',
  },
];

// @flow
import React from 'react';
import type {Node} from 'react';
import {useSearchParams} from 'react-router-dom';
// $FlowFixMe
import {Stack} from '@wellstone-solutions/web';
import {useStores} from 'hooks/useStores';
import {BackButton} from 'components/BackButton';
import {Form} from './Form';
import {AnswerModel} from '../../models/answer';

export const New = (): Node => {
  const {resourceStore, resourcesUIStore} = useStores();
  const [searchParams] = useSearchParams();
  const questionId = searchParams.get('question') || '';

  return (
    <>
      <Stack sx={{mb: 2}} direction="row">
        <BackButton path={`../../questions/${questionId}`} />
      </Stack>
      <Form
        isNew={true}
        answer={AnswerModel.Factory({
          question: {
            id: questionId,
          },
          state: resourcesUIStore.location,
        })}
        onSave={(answer) => resourceStore.createAnswer(answer)}
      />
    </>
  );
};

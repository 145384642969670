// @flow
import React, {useRef} from 'react';
import type {Node} from 'react';
// $FlowFixMe
import {ThemeProvider} from '@wellstone-solutions/web';
import {StoresProvider} from './StoresContext';
import {RootStore} from './RootStore';

type PropsType = {
  children: Node,
};

export const Providers = ({children}: PropsType): Node => {
  const rootStore = useRef(new RootStore());

  return (
    <StoresProvider rootStore={rootStore.current}>
      <ThemeProvider>{children}</ThemeProvider>
    </StoresProvider>
  );
};

// @flow
// $FlowIgnore
import {Factories} from '@wellstone-solutions/common';
import type {AlertType} from '../../types';

const DEFAULT_DURATION = 6000;

export const Factory = (overrides?: AlertType): AlertType =>
  Factories.createFactory(
    {
      id: -1,
      duration: DEFAULT_DURATION,
      message: '',
      severity: undefined,
    },
    overrides,
  );

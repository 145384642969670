// @flow

export const baseUrl = process.env.REACT_APP_API_URL;

export const routes = {
  login: '/auth/login',
  logout: '/auth/logout',
  me: '/users/me',
  orgs: '/orgs',
  resources: {
    announcements: '/resources/announcements',
    announcement: '/resources/announcements/:id',
    answers: '/resources/answers',
    answer: (id: string): string => `/resources/answers/${id}`,
    categories: '/resources/groups',
    phases: '/resources/phases',
    questions: '/resources/questions',
    question: '/resources/questions/:id',
  },
  validateCode: '/auth/validate-code',
};

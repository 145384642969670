// @flow
import React from 'react';
import type {Node} from 'react';
import {Link} from '@mui/material';

import {
  Box,
  Card,
  MarkdownViewer,
  Stack,
  Typography,
  // $FlowFixMe
} from '@wellstone-solutions/web';

import {DelimiterToStack} from 'components/DelimitertoStack';
// $FlowFixMe
import {Theme} from '@wellstone-solutions/common';
import type {PreviewType} from '../../../types';

type PropsType = {
  fields: PreviewType,
};

const ellipsisStyle = {
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
};

export const Preview = ({fields}: PropsType): Node => {
  return (
    <Box>
      {/* <Typography variant="h5" sx={{mb: 4}}>
        Preview
      </Typography> */}
      <Card sx={{p: 2, maxHeight: '65vh', minHeight: 420, overflowY: 'auto'}}>
        <Stack>
          {!!fields.title && (
            <Typography
              variant="h6"
              sx={{fontWeight: 'bold'}}
              data-testid={'answer-preview-title'}>
              {fields.title}
            </Typography>
          )}
          {!!fields.subtitle && (
            <Typography
              variant="h7"
              sx={{color: Theme.colorPalette.blue}}
              data-testid={'answer-preview-subtitle'}>
              {fields.subtitle}
            </Typography>
          )}
          {!!fields.description && (
            <MarkdownViewer value={fields.description} />
          )}
          {!!fields.organizationTitle && (
            <Typography variant="h8" sx={{mt: 1}}>
              {fields.organizationTitle}
            </Typography>
          )}
          {fields.contacts?.map((contact, index) => (
            <Stack key={index}>
              {!!contact.title && (
                <Typography
                  variant="h6"
                  sx={{
                    color: Theme.colorPalette.green,
                    mt: 2,
                  }}>
                  {contact.title}
                </Typography>
              )}
              {!!contact.phoneNumber && (
                <Link
                  sx={{...ellipsisStyle, mt: 1}}
                  href={contact.phoneNumber}
                  underline="none">
                  {contact.phoneNumberLabel || contact.phoneNumber}
                </Link>
              )}
              {!!contact.email && (
                <Link href={contact.email} underline="none" sx={ellipsisStyle}>
                  {contact.emailLabel || contact.email}
                </Link>
              )}
              {!!contact.website && (
                <Link
                  href={contact.website}
                  underline="none"
                  sx={ellipsisStyle}>
                  {contact.websiteLabel || contact.website}
                </Link>
              )}
              {!!contact.dateTimeInformation && (
                <Typography variant="h7" sx={{mt: 1}}>
                  <DelimiterToStack value={contact.dateTimeInformation} />
                </Typography>
              )}
              {!!contact.address1 && (
                <Typography variant="h7" sx={{mt: 1}}>
                  {contact.address1}
                </Typography>
              )}
              {!!contact.address2 && (
                <Typography variant="h7">{contact.address2}</Typography>
              )}
              {(!!contact.city || !!contact.state || !!contact.zip) && (
                <Typography variant="h7">
                  {contact.city} {contact.state} {contact.zip}
                </Typography>
              )}
            </Stack>
          ))}
        </Stack>
      </Card>
    </Box>
  );
};

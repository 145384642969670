// @flow

import {
  ACCEPTED_IMAGE_TYPES,
  INVALID_IMAGE_TYPE_ERROR,
  MAX_INLINE_FILE_SIZE,
  MAX_INLINE_FILE_ERROR,
} from 'constants/images';

export const richTextImageValidators = (image: Blob): string | null => {
  if (!ACCEPTED_IMAGE_TYPES.includes(image.type)) {
    return INVALID_IMAGE_TYPE_ERROR;
  }

  if (image.size >= MAX_INLINE_FILE_SIZE) {
    return MAX_INLINE_FILE_ERROR;
  }

  return null;
};

// @flow
import React from 'react';
import type {Node} from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import {Api} from '@wellstone-solutions/common';
import {Routes} from 'components/Routes';
import {baseUrl} from 'api';
import {AppContainer, ErrorBoundary} from 'modules/app';
import {Providers} from './Providers';
import {routes} from './routes';

Api.configure({
  baseURL: baseUrl,
  headers: {
    common: {
      'x-client-version': `navigator,${String(
        process.env.REACT_APP_NAVIGATOR_VERSION,
      )}`,
      'x-user-time-zone': Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
  },
});

const App = (): Node => {
  return (
    <ErrorBoundary>
      <Providers>
        <AppContainer>
          <Router basename={process.env.PUBLIC_URL}>
            <Routes routes={routes} />
          </Router>
        </AppContainer>
      </Providers>
    </ErrorBoundary>
  );
};

export default App;

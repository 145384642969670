// @flow
import React from 'react';
import type {Node} from 'react';
import {styled} from '@mui/material/styles';
// $FlowFixMe
import {Theme} from '@wellstone-solutions/common';
// $FlowFixMe
import {Box, Typography} from '@wellstone-solutions/web';

const ErrorMessage = styled(Typography)(({theme}) => ({
  color: Theme.colorPalette.negative,
  fontSize: 20,
  margin: '0 auto',
  marginTop: theme.spacing(4),
  maxWidth: 700,
}));

type PropsType = {
  message: string,
};

export const ErrorPage = ({message}: PropsType): Node => (
  <Box
    sx={{
      fontSize: 30,
      padding: 10,
      textAlign: 'center',
    }}>
    <Typography variant="h1" sx={{fontSize: 30}}>
      Oops! There was an error. 😬
    </Typography>
    <ErrorMessage>{message}</ErrorMessage>
  </Box>
);

// @flow
// $FlowFixMe
import {Box} from '@wellstone-solutions/web';
import type {Node} from 'react';

type PropsType = {children: Node};

export const PageContainer = ({children}: PropsType): Node => (
  <Box
    component="main"
    sx={{
      backgroundColor: (theme) =>
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[900],
      flexGrow: 1,
      height: 'auto',
      overflow: 'auto',
      pb: 4,
      pt: 12,
      pl: 4,
      pr: 4,
    }}>
    {children}
  </Box>
);

//@flow
import type {ApiFeatureFlagData} from './types';
import type {
  ApiOrganizationType,
  ApiProgramType,
  ApiUserType,
  ApiResponseType,
  ApiFeatureFlagType,
  FeatureFlagStrategyEnum,
} from '@wellstone-solutions/common';
import type {IObservableArray} from 'mobx';

import {Models} from '@wellstone-solutions/common';
import {RootStore} from 'RootStore';
import {filterAllowedFlags, convertStrategyNameToId} from './utils';

import {action, makeObservable, observable, runInAction} from 'mobx';

export class CustomerServiceStore {
  featureFlags: IObservableArray<ApiFeatureFlagData> = observable.array();
  users: IObservableArray<ApiUserType> = observable.array();
  organizations: IObservableArray<ApiOrganizationType> = observable.array();
  programs: IObservableArray<ApiProgramType> = observable.array();

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      featureFlags: observable,
      updateUserEmail: action,
      addFlagOwner: action,
      refreshFlags: action,
      users: observable,
      organizations: observable,
      programs: observable,
    });

    this.rootStore = rootStore;
  }

  async init(): Promise<void> {
    const [flagsResponse, userResponse, orgResponse, programResponse] =
      await Promise.all([
        Models.Super.FeatureFlag.getAll(),
        Models.Super.User.getAll(),
        Models.Super.Organization.getAll(),
        Models.Super.Organization.getAllPrograms(),
      ]);

    runInAction(() => {
      if (flagsResponse.isSuccess) {
        const filtered = filterAllowedFlags(flagsResponse.data);
        this.featureFlags.replace(filtered);
      }
      if (userResponse.isSuccess) {
        // $FlowFixMe. Wrong type on the common libs side
        this.users.replace(userResponse.data);
      }
      if (orgResponse.isSuccess) {
        // $FlowFixMe.
        this.organizations.replace(orgResponse.data.organizations);
      }
      if (programResponse.isSuccess) {
        // $FlowFixMe
        this.programs.replace(programResponse.data.programs);
      }
    });
  }

  async refreshFlags(): Promise<void> {
    const response = await Models.Super.FeatureFlag.getAll();
    if (response.isSuccess && response.data) {
      runInAction(() => {
        const filtered = filterAllowedFlags(response.data);
        this.featureFlags.replace(filtered);
      });
    }
  }

  async updateUserEmail(values: any): Promise<ApiResponseType<ApiUserType>> {
    const res = await Models.Super.User.update(values.id, {
      email: values.updatedEmail,
    });

    if (res.isSuccess) {
      await this.refreshFlags();
      return res;
    } else {
      // Errors are coming back on the data object.  Extract the email errors.
      // $FlowFixMe
      return {...res, errors: res.data.email};
    }
  }

  async addFlagOwner(
    values: any,
  ): Promise<ApiResponseType<ApiFeatureFlagType>> {
    const {selectedFlagId, ownerId} = values;
    const selectedFlag = this.featureFlags.find((f) => f.id === selectedFlagId);
    const key = convertStrategyNameToId(selectedFlag.strategy);
    const payload = {
      [key]: ownerId,
      strategy: selectedFlag.strategy,
    };
    const res = await Models.Super.FeatureFlag.addFeatureFlagOwner(
      selectedFlagId,
      payload,
    );
    if (res.isSuccess) {
      await this.refreshFlags();
    }
    return res;
  }

  async removeOwnerFromFeatureFlag(row: {
    flagId: string,
    strategy: FeatureFlagStrategyEnum,
    ownerId: string,
  }): Promise<ApiResponseType<ApiFeatureFlagType>> {
    const {flagId, strategy, ownerId} = row;
    const attrForOwner = convertStrategyNameToId(strategy);
    const res = await Models.Super.FeatureFlag.deleteFeatureFlagOwner(flagId, {
      strategy,
      [attrForOwner]: ownerId,
    });

    if (res.isSuccess) {
      this.refreshFlags();
    }
    return res;
  }
}

// @flow
import {useCallback, useEffect, useRef, useState} from 'react';
import type {ComponentType, Node} from 'react';
import {observer} from 'mobx-react';
import {
  Chip,
  Box,
  Stack,
  Typography,
  // $FlowFixMe
} from '@wellstone-solutions/web';
import {Hooks} from '@wellstone-solutions/common';
import {BackButton} from 'components/BackButton';
import {PublishedStatusChip} from 'components/PublishedStatusChip';
import {useStores} from 'hooks/useStores';
import {AnnouncementModel} from '../../models/announcement';
import {Form} from '../Form';
import {Preview} from '../Preview';
import {AdjacentList} from '../AdjacentList';
import {PublishButtonBar} from './PublishButtonBar';
import type {APIAnnouncementType} from '../../types';
import {formatDate} from 'utils/date';

const FORM_ID = 'details_form';

type PropsType = {
  fetchData: () => Promise<void>,
  preloadedData: APIAnnouncementType,
};

export const Detail: ComponentType<PropsType> = observer(
  ({fetchData, preloadedData}: PropsType): Node => {
    const contentRef = useRef();
    const {alertStore, announcementStore, appUIStore} = useStores();
    const announcement = AnnouncementModel.toUI(
      preloadedData,
      Array.from(announcementStore.organizations),
    );
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isPublishing, setIsPublishing] = useState(false);
    const [isUnpublishing, setIsUnpublishing] = useState(false);

    const form = Hooks.useForm({
      initialValues: AnnouncementModel.toForm(announcement),
      schema: AnnouncementModel.schema,
      onSubmit: async (values, {resetForm}) => {
        setIsSubmitting(true);

        const updatedAnnouncement = AnnouncementModel.toUIFromForm(
          announcement,
          values,
        );

        const response = await announcementStore.updateAnnouncement(
          updatedAnnouncement,
        );

        if (response.isSuccess) {
          alertStore.addSuccess('Saved announcement');

          resetForm({
            values: AnnouncementModel.toForm(
              AnnouncementModel.toUI(
                response.data,
                Array.from(announcementStore.organizations),
              ),
            ),
          });
        } else {
          alertStore.addError('Failed to update announcement', response.errors);
        }

        setIsSubmitting(false);
      },
    });

    const isTouched = Object.keys(form.touched).length > 0;
    const hasErrors = Object.keys(form.errors).length > 0;

    const isScheduledForToday = AnnouncementModel.isScheduledForToday(
      form.values,
    );

    useEffect(() => {
      announcementStore.setAdjacentAnnouncements(
        form.values.scheduled,
        form.values.id,
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form.values.scheduled]);

    useEffect(() => {
      if (isTouched) {
        appUIStore.setIsDirty(true);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isTouched]);

    useEffect(
      () => () => appUIStore.setIsDirty(false),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [],
    );

    const handleRevertChanges = useCallback(() => {
      const originalFormAnnouncement = AnnouncementModel.toForm(announcement);

      const instance =
        contentRef.current && contentRef.current.getInstance
          ? contentRef.current.getInstance()
          : null;

      if (instance) {
        instance.setMarkdown(originalFormAnnouncement.content, false);
      }

      form.resetForm({
        values: originalFormAnnouncement,
        touched: {},
        errors: {},
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handlePublishNow = useCallback(() => {
      setIsPublishing(true);
    }, []);

    const handlePublishConfirmation = useCallback(async () => {
      setIsSubmitting(true);

      const response = await announcementStore.publishAnnouncement(
        String(announcement.id),
      );

      if (response.isSuccess) {
        alertStore.addSuccess(
          `Published announcement to ${announcementStore.recipientName}`,
        );
        setIsPublishing(false);
        fetchData();
      } else {
        alertStore.addError('Failed to publish announcement', response.errors);
      }

      setIsSubmitting(false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleCancelPublish = useCallback(() => {
      setIsPublishing(false);
    }, []);

    const handleUnpublish = useCallback(() => {
      setIsUnpublishing(true);
    }, []);

    const handleConfirmUnpublish = useCallback(async () => {
      setIsSubmitting(true);

      const response = await announcementStore.unpublishAnnouncement(
        String(announcement.id),
      );

      if (response.isSuccess) {
        fetchData();
        alertStore.addSuccess('Unpublished announcement');
        setIsPublishing(false);
      } else {
        alertStore.addError(
          'Failed to unpublish announcement',
          response.errors,
        );
      }

      setIsSubmitting(false);
      setIsUnpublishing(false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleCancelUnpublish = useCallback(() => {
      setIsUnpublishing(false);
    }, []);

    const isPublished = AnnouncementModel.isPublished(announcement);

    return (
      <>
        <Stack sx={{mb: 2}} direction="row">
          <BackButton path="../../announcements" />
        </Stack>
        <Stack flexDirection="row" alignItems="flex-start">
          <Box flex={2}>
            <Stack flexDirection="row">
              <Typography variant="h6" sx={{mb: 4, mr: 2, fontWeight: 'bold'}}>
                {announcementStore.recipientName} Announcement
              </Typography>
              <PublishedStatusChip status={announcement.status} />
              {AnnouncementModel.wasPublished(announcement) ? (
                <Chip
                  label={`Last Published on ${formatDate(
                    announcement.publishedOn,
                  )}`}
                  color="warning"
                  variant="filled"
                  sx={{ml: 1}}
                />
              ) : null}
            </Stack>
            <Stack flexDirection="column">
              <Form
                ref={contentRef}
                isDisabled={isPublished || isPublishing}
                form={form}
                formId={FORM_ID}
              />
            </Stack>
            <PublishButtonBar
              formId={FORM_ID}
              handlePublishNow={handlePublishNow}
              handlePublishConfirmation={handlePublishConfirmation}
              handleCancelPublish={handleCancelPublish}
              handleUnpublish={handleUnpublish}
              handleConfirmUnpublish={handleConfirmUnpublish}
              handleCancelUnpublish={handleCancelUnpublish}
              handleRevertChanges={handleRevertChanges}
              hasErrors={hasErrors}
              isDraft={
                AnnouncementModel.isDraft(announcement) ||
                AnnouncementModel.isDeleted(announcement)
              }
              isPublished={isPublished}
              isPublishing={isPublishing}
              isUnpublishing={isUnpublishing}
              isScheduledForToday={isScheduledForToday}
              isSubmitting={isSubmitting}
              isTouched={isTouched}
              recipientName={announcementStore.recipientName}
            />
          </Box>
          <Box flex={1}>
            <Stack flexDirection="column" sx={{pl: 4, pt: 11}}>
              <Preview fields={{...form.values}} />
            </Stack>
          </Box>
        </Stack>
        <Stack flexDirection="column" sx={{mt: 4}}>
          <AdjacentList />
        </Stack>
      </>
    );
  },
);

// @flow
import {action, makeObservable, observable} from 'mobx';
import type {IObservableArray} from 'mobx';
import {RootStore} from 'RootStore';
import type {ApiErrorType} from '@wellstone-solutions/common';
import {AlertModel} from './models/alert';
import type {AlertType} from './types';
import {AlertSeverity} from './constants';

const extractError = (error: ApiErrorType | string): string | ApiErrorType => {
  if (error.message && error.field) {
    const {field, message} = error;
    return `${String(field)} ${String(message).toLowerCase()}`;
  }

  return error;
};
export class AlertStore {
  alerts: IObservableArray<AlertType> = observable.array();
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      add: action,
      alerts: observable,
      remove: action,
    });

    this.rootStore = rootStore;
  }

  add(message: string, severity: string) {
    const alert = AlertModel.Factory({
      id: this.alerts.length + 1,
      message,
      severity,
    });

    this.alerts.push(alert);
  }

  addSuccess(message: string): void {
    this.add(message, AlertSeverity.SUCCESS);
  }

  addError(message: string, errors: Array<string | ApiErrorType> = []): void {
    this._addWithErrors(message, errors, AlertSeverity.ERROR);
  }

  addWarning(message: string, errors: Array<string | ApiErrorType> = []): void {
    this._addWithErrors(message, errors, AlertSeverity.WARNING);
  }

  addInfo(message: string): void {
    this.add(message, AlertSeverity.INFO);
  }

  remove(id: number): void {
    this.alerts.replace(this.alerts.filter((alert) => alert.id !== id));
  }

  _addWithErrors(
    message: string,
    errors: Array<string | ApiErrorType> = [],
    severity: string,
  ) {
    const errorsMessage =
      errors?.length > 0 ? `: ${errors.map(extractError).join(', ')}` : '';
    this.add(`${message}${errorsMessage}`, severity);
  }
}

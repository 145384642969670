// @flow
import React from 'react';
import type {Node} from 'react';
import {LayoutDrawer} from 'modules/app';
import {UpdateEmail} from './UpdateEmail';
import {UpdateRoles} from './UpdateRoles';
import {FeatureFlags} from './FeatureFlags';
import {PageContainer} from 'components/PageContainer';
import {Grid} from '@wellstone-solutions/web';

export const Root = (): Node => {
  return (
    <LayoutDrawer>
      <PageContainer>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <UpdateEmail />
          </Grid>
          <Grid item xs={4}>
            <UpdateRoles />
          </Grid>
          <Grid item xs={12}>
            <FeatureFlags />
          </Grid>
        </Grid>
      </PageContainer>
    </LayoutDrawer>
  );
};

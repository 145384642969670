// @flow
// $FlowIgnore
import {Factories} from '@wellstone-solutions/common';
import type {UIMeType} from '../../types';

export const Factory = (overrides?: UIMeType): UIMeType =>
  Factories.createFactory(
    {
      id: '',
      data: {},
      language: 'en',
      memberships: [],
      name: '',
      publicKey: '',
      region: 'US',
      systemPublicKey: '',
    },
    overrides,
  );

// @flow
// TODO: For when we have Sentry setup
// import * as Sentry from '@sentry/browser';
import React, {Component} from 'react';
import type {Node} from 'react';
import {ErrorPage} from '../ErrorPage';

interface IError {
  message: string;
}

type PropsType = {
  children: Node,
};

type StateType = {
  error: null | IError,
};

export class ErrorBoundary extends Component<PropsType, StateType> {
  state: StateType = {
    error: null,
  };

  constructor(props: PropsType) {
    super(props);
  }

  componentDidCatch(error: IError) {
    this.setState({error});
  }

  componentDidMount() {
    // TODO: For when we have sentry setup
    // Sentry.init({
    //   dsn: SENTRY_DSN,
    //   environment: SENTRY_ENV,
    // });
    // Sentry.configureScope((scope) => {
    //   scope.setTag('source', 'navigator');
    // });
  }

  render(): Node {
    const {error} = this.state;
    // eslint-disable-next-line react/prop-types
    const {children} = this.props;

    return error ? <ErrorPage message={error.message} /> : children;
  }
}

//@flow
import type {ApiFeatureFlagData} from './types';
import type {IObservableArray} from 'mobx';
import type {FeatureFlagTableRow} from './types';
import {FLAG_STRATEGIES} from './constants';

const {selectUsers, selectProgram, selectOrg} = FLAG_STRATEGIES;

const ALLOWED_STRATEGIES = [selectUsers, selectProgram, selectOrg];

export const convertStrategyNameToId = (strategy: string): string => {
  const strategyToIdMap = {
    select_users: 'user_id',
    select_org: 'organization_id',
    select_program: 'program_id',
  };
  return strategyToIdMap[strategy];
};

export const filterAllowedFlags = (
  flags: Array<any>,
): Array<ApiFeatureFlagData> => {
  // Filter the flags on strategy
  const filtered = flags.filter((flag) =>
    ALLOWED_STRATEGIES.includes(flag.strategy),
  );

  // Sort the filtered flags by name
  return filtered.sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });
};

export const flattenFlags = (
  allFeatureFlags: IObservableArray<ApiFeatureFlagData>,
): FeatureFlagTableRow[] => {
  const output: FeatureFlagTableRow[] = [];

  const processFlag = (flag) => {
    if (flag.strategy === selectUsers) {
      flag.users.forEach((user) => {
        const item = {
          id: `${flag.id}-${user.id}`,
          desc: flag.desc,
          email: user.email,
          flag: flag.name,
          flagId: flag.id,
          name: user.name,
          ownerId: user.id,
          ownerName: user.name,
          strategy: selectUsers,
        };
        output.push(item);
      });
    }

    if (flag.strategy === selectProgram) {
      flag.programs.forEach((program) => {
        const item = {
          id: `${flag.id}-${program.id}`,
          desc: flag.desc,
          flag: flag.name,
          flagId: flag.id,
          name: program.name,
          orgName: program.organization.name,
          ownerId: program.id,
          ownerName: program.name,
          strategy: selectProgram,
        };
        output.push(item);
      });
    }

    if (flag.strategy === selectOrg) {
      flag.organizations.forEach((org) => {
        const item = {
          id: `${flag.id}-${org.id}`,
          desc: flag.desc,
          flag: flag.name,
          flagId: flag.id,
          name: org.name,
          owner: org.id,
          ownerId: org.id,
          ownerName: org.name,
          strategy: selectOrg,
        };
        output.push(item);
      });
    }
  };

  allFeatureFlags.map(processFlag);

  return output;
};

// @flow
import {makeObservable} from 'mobx';
import {RootStore} from 'RootStore';
export class DashboardStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      // Add things here...
    });

    this.rootStore = rootStore;
  }
}

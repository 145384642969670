// @flow
import React from 'react';
import type {Node} from 'react';

import {
  Box,
  Card,
  MarkdownViewer,
  Stack,
  Typography,
  // $FlowFixMe
} from '@wellstone-solutions/web';
import {Theme} from '@wellstone-solutions/common';

import type {AnnouncementPreviewType} from '../../types';

type PropTypes = {
  fields: AnnouncementPreviewType,
};

export const Preview = ({fields}: PropTypes): Node => {
  return (
    <Box>
      <Typography variant="h6" sx={{mb: 4, fontWeight: 'bold'}}>
        Preview
      </Typography>

      <Card sx={{p: 2, minHeight: 300}}>
        <Stack>
          {!!fields.title && (
            <Typography
              variant="h6"
              sx={{
                fontWeight: 700,
                fontSize: 24,
                color: Theme.colorPalette.primary,
                borderColor: Theme.colorPalette.primary,
                borderBottom: '1px solid',
              }}>
              {fields.title}
            </Typography>
          )}

          {!!fields.content && <MarkdownViewer value={fields.content} />}
        </Stack>
      </Card>
    </Box>
  );
};

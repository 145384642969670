// @flow
import {useContext} from 'react';
import {StoresContext} from 'StoresContext';
import type {RootStore} from 'RootStore';

export const useStores = (): RootStore => {
  const stores = useContext(StoresContext);

  if (stores === undefined) {
    throw new Error('useStores must be used within a StoresProvider');
  }

  return stores;
};

// @flow
import React from 'react';
import type {Node} from 'react';
import {Navigate} from 'react-router-dom';
import {Routes as SharedRoutes} from 'components/Routes';
import {routes as questionRoutes} from './Questions/routes';
import {routes as answerRoutes} from './Answers/routes';

export const routes = [
  {
    Component: (): Node => <SharedRoutes routes={answerRoutes} />,
    isProtected: true,
    label: 'All Answers',
    path: 'answers/*',
  },
  {
    Component: (): Node => <SharedRoutes routes={questionRoutes} />,
    isProtected: true,
    label: 'All Questions',
    path: 'questions/*',
  },
  {
    Component: (): Node => <Navigate to={`./questions`} />,
    isProtected: true,
    path: '*',
  },
];

// @flow
import {action, makeObservable, observable} from 'mobx';
import {RootStore} from 'RootStore';
import {root, defaultDomain} from './constants';

export class ContentManagementUIStore {
  domain: string = defaultDomain;
  rootStore: RootStore | null = null;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      domain: observable,
      setDomain: action,
    });

    this.rootStore = rootStore;
  }

  getBaseUrl: () => string = (): string => {
    return '/' + [root, this.domain].join('/');
  };

  setDomain(domain: string): void {
    this.domain = domain;
  }
}

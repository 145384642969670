// @flow
import type {Node} from 'react';
import {
  Button,
  ErrorMessage,
  FormRow,
  Icon,
  IconNames,
  TextField,
  Typography,
  Stack,
  // $FlowFixMe
} from '@wellstone-solutions/web';
import {StateSelect} from 'components/StateSelect';
import type {UIContactType} from '../../../types';

type PropsType = {
  errors: mixed,
  apiErrors: mixed,
  touched: mixed,
  item: UIContactType,
  index: number,
  removeItem: () => void,
  updateItem: (UIContactType) => void,
};

export const ContactItem = ({
  errors,
  apiErrors,
  touched,
  item,
  index,
  removeItem,
  updateItem,
}: PropsType): Node => (
  <Stack direction="column" mb={1}>
    <FormRow>
      <TextField
        fullWidth
        formField="contacts.title"
        label="Contact Title"
        onChange={(e) => {
          updateItem({...item, title: e.target.value});
        }}
        value={item.title}
      />
    </FormRow>
    <FormRow direction="row">
      <TextField
        fullWidth
        formField="contacts.phoneNumber"
        label="Phone Number"
        onChange={(e) => {
          updateItem({
            ...item,
            phoneNumber: e.target.value,
          });
        }}
        value={item.phoneNumber}
        sx={{mr: 1}}
      />
      <TextField
        fullWidth
        formField="contacts.phoneNumberLabel"
        label="Label"
        onChange={(e) => {
          updateItem({
            ...item,
            phoneNumberLabel: e.target.value,
          });
        }}
        value={item.phoneNumberLabel}
      />
    </FormRow>
    <FormRow direction="row">
      <TextField
        fullWidth
        formField="contacts.email"
        label="Email"
        onChange={(e) => {
          updateItem({...item, email: e.target.value});
        }}
        value={item.email}
        sx={{mr: 1}}
      />
      <TextField
        fullWidth
        formField="contacts.emailLabel"
        label="Label"
        onChange={(e) => {
          updateItem({
            ...item,
            emailLabel: e.target.value,
          });
        }}
        value={item.emailLabel}
      />
    </FormRow>
    <Stack direction="row">
      <ErrorMessage
        name={`contacts.${index}.email`}
        errors={errors}
        apiErrors={apiErrors}
        touched={touched}
      />
    </Stack>
    <FormRow direction="row">
      <TextField
        fullWidth
        formField="contacts.website"
        label="Website"
        onChange={(e) => {
          updateItem({...item, website: e.target.value});
        }}
        value={item.website}
        sx={{mr: 1}}
      />
      <TextField
        fullWidth
        formField="contacts.websiteLabel"
        label="Label"
        onChange={(e) => {
          updateItem({
            ...item,
            websiteLabel: e.target.value,
          });
        }}
        value={item.websiteLabel}
      />
    </FormRow>
    <Stack direction="row">
      <ErrorMessage
        name={`contacts.${index}.website`}
        errors={errors}
        apiErrors={apiErrors}
        touched={touched}
      />
    </Stack>
    <FormRow>
      <TextField
        formField="dateTimeInformation"
        multiline
        minRows={8}
        label="Date & Time Information"
        onChange={(e) => {
          updateItem({
            ...item,
            dateTimeInformation: e.target.value,
          });
        }}
        value={item.dateTimeInformation}
      />
    </FormRow>
    <FormRow>
      <Typography
        variant="h7"
        sx={{
          fontWeight: 'bold',
          mt: 4,
        }}>
        Location
      </Typography>
    </FormRow>
    <Stack direction="row">
      <ErrorMessage
        name={`contacts.${index}.address`}
        errors={errors}
        apiErrors={apiErrors}
        touched={touched}
      />
    </Stack>
    <FormRow direction="row">
      <TextField
        fullWidth
        formField="contacts.address1"
        label="Address 1"
        onChange={(e) => {
          updateItem({...item, address1: e.target.value});
        }}
        value={item.address1}
        sx={{mr: 1}}
      />
      <TextField
        fullWidth
        formField="contacts.address2"
        label="Address 2"
        onChange={(e) => {
          updateItem({
            ...item,
            address2: e.target.value,
          });
        }}
        value={item.address2}
      />
    </FormRow>
    <FormRow direction="row">
      <TextField
        formField="contacts.city"
        label="City"
        onChange={(e) => {
          updateItem({...item, city: e.target.value});
        }}
        value={item.city}
        sx={{mr: 1, flex: 1}}
      />
      <StateSelect
        formField="contacts.state"
        onChange={(e) => updateItem({...item, state: e.target.value})}
        value={item.state}
        universal={false}
        sx={{mr: 1, flex: 1}}
      />
      <TextField
        formField="contacts.zip"
        label="Zip"
        onChange={(e) => {
          updateItem({
            ...item,
            zip: e.target.value,
          });
        }}
        value={item.zip}
        sx={{flex: 1}}
      />
    </FormRow>
    <FormRow>
      <Stack alignItems="center">
        <Button onClick={removeItem} sx={{mt: 2}}>
          <Icon name={IconNames.XmarkCircle} />
        </Button>
      </Stack>
    </FormRow>
  </Stack>
);

// @flow
import type {Node} from 'react';
import {
  Button,
  Box,
  Icon,
  IconNames,
  // $FlowFixMe
} from '@wellstone-solutions/web';
// $FlowFixMe
import {Theme} from '@wellstone-solutions/common';

type PropsType = {
  children: Node,
  iconColor?: string,
  iconName?: string,
  onClick: () => void,
  color?: string,
  variant?: string,
  ...
};

export const IconButton = ({
  children,
  iconColor = Theme.colorPalette.light,
  iconName = IconNames.Plus,
  onClick,
  color = 'primary',
  variant = 'contained',
  ...rest
}: PropsType): Node => (
  <Button {...rest} variant={variant} color={color} onClick={onClick}>
    <Box
      sx={{
        display: 'flex',
        mr: 1,
      }}>
      <Icon name={iconName} color={iconColor} />
    </Box>{' '}
    {children}
  </Button>
);

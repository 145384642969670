// @flow
// $FlowFixMe
import {Validation} from '@wellstone-solutions/common';

const {isEmail, isOptional, isUrl} = Validation;

export const schema = {
  title: isOptional,
  email: {
    validate: (value: string): boolean =>
      !value || value.length === 0 || isEmail(value),
    error: 'Invalid email',
  },
  emailLabel: isOptional,
  phoneNumber: isOptional,
  phoneNumberLabel: isOptional,
  website: {
    validate: (value: mixed): boolean =>
      !value || value.length === 0 || isUrl(value),
    error: 'Invalid website',
  },
  websiteLabel: isOptional,
  dateTimeInformation: isOptional,
  address1: isOptional,
  address2: isOptional,
  city: isOptional,
  state: isOptional,
  zip: isOptional,
};

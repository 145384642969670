// @flow
import {FormControl, FormLabel} from '@mui/material';
import {Typography, TextField, Button, Form} from '@wellstone-solutions/web';
import {Hooks, Validation} from '@wellstone-solutions/common';
import {useStores} from 'hooks/useStores';
import {RadioGroup} from 'components/RadioGroup';
import type {ComponentType, Node} from 'react';
import type {RadioGroupOptions} from 'components/RadioGroup';

const UNIQUE_FIELD_ERROR = 'This field must be unique.';

type RadioFormProps = {
  options: RadioGroupOptions,
  resetSearch: () => void,
};

export const RadioForm: ComponentType<RadioFormProps> = ({
  options,
  resetSearch,
}: RadioFormProps): Node => {
  const {alertStore, customerServiceStore} = useStores();
  const {isRequired, isEmail} = Validation;
  const {useForm} = Hooks;

  const handleSubmit = async (values, form) => {
    const {isSuccess, errors} = await customerServiceStore.updateUserEmail(
      values,
    );

    if (!isSuccess) {
      const friendlyErrors = errors.map((error) =>
        error === UNIQUE_FIELD_ERROR ? 'This email is already in use.' : error,
      );

      alertStore.addError(
        friendlyErrors.length > 0
          ? friendlyErrors.join(' ')
          : 'Email Update Failed!',
      );
      return;
    }

    alertStore.addSuccess('Email Updated!');
    form.resetForm({});
    resetSearch();
  };

  const form = useForm({
    initialValues: {id: '', updatedEmail: ''},
    schema: {
      id: isRequired,
      updatedEmail: isRequired && isEmail,
    },
    onSubmit: handleSubmit,
  });

  if (!options || options?.length <= 0) {
    return null;
  }

  return (
    <Form onSubmit={form.handleSubmit}>
      <FormControl
        component="fieldset"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          '& > *': {
            marginTop: '1rem',
          },
        }}>
        <FormLabel>
          <Typography variant="subtitle1">Select an email:</Typography>
        </FormLabel>
        <RadioGroup
          field={{value: form.values.id, onChange: form.handleChange}}
          options={options}
        />
        <FormLabel id="updatedEmail" htmlFor="updatedEmail">
          <Typography variant="subtitle1">Update email:</Typography>
        </FormLabel>
        <TextField
          test-id="update-email"
          id="updatedEmail"
          name="updatedEmail"
          placeholder="New Email"
          value={form.values.updatedEmail || ''}
          onChange={form.handleChange}
        />
        <Button
          sx={{
            marginTop: '1rem',
            alignSelf: 'flex-end',
          }}
          disabled={!form.dirty || !form.isValid || form.isSubmitting}
          variant="contained"
          type="submit"
          date-testid="radioform-submit">
          Update
        </Button>
      </FormControl>
    </Form>
  );
};

// @flow

import {styled} from '@mui/material/styles';
import type {Node} from 'react';
// $FlowFixMe
import {Chip} from '@wellstone-solutions/web';
// $FlowFixMe
import {Theme} from '@wellstone-solutions/common';
import type {RecipientType} from 'modules/announcements/types';

type PropsType = {
  recipient: RecipientType,
};

const recipientColors = {
  by_org: Theme.colorPalette.primary,
  by_region: Theme.colorPalette.green,
  all_users: Theme.colorPalette.blueDark,
};

const StyledChip = styled(Chip)(({recipient}) => ({
  backgroundColor: `${recipientColors[recipient.type]}`,
  color: 'white',
  border: 'none',
  paddingTop: '3px',
}));

export const RecipientChip = ({recipient}: PropsType): Node => (
  <StyledChip recipient={recipient} label={recipient.name.toUpperCase()} />
);

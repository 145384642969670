// @flow
import {List} from './List';
import {Detail} from './Detail';
import {New} from './New';

export const routes = [
  {
    Component: List,
    isProtected: true,
    index: true,
  },
  {
    Component: Detail,
    isProtected: true,
    path: ':id',
  },
  {
    Component: New,
    isProtected: true,
    path: 'new',
  },
  {
    Component: List,
    isProtected: true,
    path: '*',
  },
];

// @flow
import React from 'react';
import type {Node} from 'react';
import {useNavigate} from 'react-router-dom';
import {styled} from '@mui/material/styles';
import {
  Button,
  ErrorMessage,
  FormRow,
  Typography,
  TextField,
  Paper,
  // $FlowFixMe
} from '@wellstone-solutions/web';
// $FlowFixMe
import {Hooks, Validation} from '@wellstone-solutions/common';
import {LayoutBasic} from 'modules/app';
import {useStores} from 'hooks/useStores';

const Form = styled('form')(({theme}) => ({
  marginTop: theme.spacing(4),
  width: '100%',
}));

const validateEmail = (email) =>
  Validation.isRequired(email) && Validation.isEmail(email);

export const Login = (): Node => {
  const navigate = useNavigate();
  const stores = useStores();

  const {authStore} = stores;

  const schema = {
    email: {
      validate: validateEmail,
      error: 'Please enter a valid email address.',
    },
  };

  const initialValues = {
    email: '',
  };

  const form = Hooks.useForm({
    initialValues,
    schema,
    onSubmit: async ({email}) => {
      await authStore.requestCode(email);
      navigate('/submit-code', {
        replace: true,
      });
    },
  });

  return (
    <LayoutBasic>
      <Paper
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          maxWidth: '100%',
          minWidth: 500,
          padding: 4,
        }}>
        <Typography component="h1" variant="h5">
          Login
        </Typography>
        <Form onSubmit={form.handleSubmit}>
          <FormRow>
            <TextField
              autoFocus
              fullWidth
              formField="email"
              onChange={form.handleFieldChange('email')}
              value={form.values.email}
              label="Email Address"
            />
            <ErrorMessage
              name="email"
              errors={form.errors}
              touched={form.touched}
            />
          </FormRow>
          <FormRow>
            <Button color="primary" variant="contained" fullWidth type="submit">
              Get Code
            </Button>
          </FormRow>
        </Form>
      </Paper>
    </LayoutBasic>
  );
};

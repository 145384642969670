// @flow
import React from 'react';
import {Link} from 'react-router-dom';
import type {ComponentType, Node} from 'react';
import {observer} from 'mobx-react';
import {useStores} from 'hooks/useStores';
// $FlowFixMe
import {DataGrid, Paper, Typography} from '@wellstone-solutions/web';
// $FlowFixMe
import type {GridColDef} from '@mui/x-data-grid';
import {PublishedStatusChip} from 'components/PublishedStatusChip';
import {RecipientChip} from 'components/RecipientChip';
import {formatDate} from 'utils/date';

export const AdjacentList: ComponentType<{}> = observer((): Node => {
  const {announcementStore} = useStores();

  const hasScheduled = announcementStore.adjacentAnnouncements.length > 0;

  const columns: GridColDef[] = [
    {field: 'id', hide: true, headerName: ''},
    {
      field: 'recipient',
      headerName: 'Recipients',
      renderCell: ({formattedValue}) => (
        <RecipientChip recipient={formattedValue} />
      ),
      width: 200,
    },
    {
      field: 'scheduled',
      headerName: 'Scheduled for',
      width: 200,
      renderCell: (value) => formatDate(value),
    },
    {
      field: 'title',
      headerName: 'Title',
      flex: 1,
      renderCell: (params) => (
        <Link to={`../${params.id}`}>{params.value}</Link>
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      renderCell: ({formattedValue}) => {
        return <PublishedStatusChip status={formattedValue.toLowerCase()} />;
      },
      width: 200,
    },
  ];

  return (
    <Paper
      sx={{
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        height: 'auto',
      }}>
      <Typography variant="h5" sx={{fontWeight: 'bold'}}>
        Other Announcements around this time
      </Typography>
      {hasScheduled ? (
        <DataGrid
          sx={{mt: 4}}
          autoHeight
          rows={announcementStore.dataGridAdjacentAnnouncements}
          columns={columns}
        />
      ) : (
        <Typography sx={{mt: 2}}>
          Select a date to see other announcements that have been published or
          are intended to be, up to 5 days prior, or 5 days after the selected
          date.
        </Typography>
      )}
    </Paper>
  );
});

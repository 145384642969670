// @flow
import type {Node} from 'react';
import {useParams} from 'react-router-dom';
// $FlowFixMe
import {Stack} from '@wellstone-solutions/web';
import {useStores} from 'hooks/useStores';
import {BackButton} from 'components/BackButton';
import {Form} from './Form';

export const Detail = (): Node | null => {
  const {resourceStore} = useStores();
  const {id} = useParams();

  const answer = resourceStore.answers.find((a) => a.id === id);

  if (!answer) {
    return null;
  }

  return (
    <>
      <Stack sx={{mb: 2}} direction="row">
        <BackButton path={-1} />
      </Stack>
      <Form
        answer={{...answer}}
        onSave={(answer) => resourceStore.updateAnswer(answer)}
      />
    </>
  );
};

// @flow
import React, {Fragment, useCallback, useEffect, useState} from 'react';
import type {Node} from 'react';
import {styled} from '@mui/material/styles';
// $FlowFixMe
import {Hooks, Theme} from '@wellstone-solutions/common';
import {
  Box,
  Button,
  FieldArray,
  Form as CommonForm,
  FormRow,
  ErrorMessage,
  Icon,
  IconNames,
  TextField,
  Typography,
  Select,
  Stack,
  // $FlowFixMe
} from '@wellstone-solutions/web';
import type {ApiResponseType} from '@wellstone-solutions/common';
import {useStores} from 'hooks/useStores';
import {isString} from 'utils/isString';
import {QuestionModel} from '../../models/question';
import type {
  APIResourceQuestionType,
  FormResourceQuestionType,
} from '../../types';

const StyledAddButton = styled(Icon)(() => ({
  color: Theme.colorPalette.lightest,
  marginLeft: 5,
  fieldset: {borderWidth: 0},
  fill: Theme.colorPalette.blueDark,
  cursor: 'pointer',
  '&:hover': {
    fill: Theme.colorPalette.lightest,
    color: Theme.colorPalette.blueDark,
  },
}));

const StyledMinusButton = styled(Icon)(() => ({
  color: Theme.colorPalette.darkest,
  border: '1px solid',
  borderRadius: '50%',
  marginLeft: 10,
  padding: 3,
  fieldset: {borderWidth: 0},
  fill: Theme.colorPalette.lightest,
  cursor: 'pointer',
  '&:hover': {fill: Theme.colorPalette.dark},
}));

type PropsType = {
  isNew?: boolean,
  question: FormResourceQuestionType,
  onSave: (
    question: FormResourceQuestionType,
  ) => Promise<ApiResponseType<APIResourceQuestionType>>,
};

export const Form = ({isNew = false, question, onSave}: PropsType): Node => {
  const {alertStore, appUIStore, resourceStore} = useStores();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const initialValues = question;

  const form = Hooks.useForm({
    initialValues,
    schema: QuestionModel.formSchema,
    onSubmit: async (values, {resetForm}) => {
      setIsSubmitting(true);
      const response = await onSave(values);

      if (response.isSuccess) {
        if (isNew) {
          alertStore.addSuccess('Successfully saved new question.');
          resetForm();
        } else {
          alertStore.addSuccess('Successfully updated question.');
        }
      } else {
        alertStore.addError('Failed to save question', response.errors);
      }

      appUIStore.setIsDirty(false);
      setIsSubmitting(false);
    },
  });

  const isTouched = Object.keys(form.touched).length > 0;
  const hasErrors = Object.keys(form.errors).length > 0;

  useEffect(() => {
    if (isTouched) {
      appUIStore.setIsDirty(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTouched]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => appUIStore.setIsDirty(false), []);

  return (
    <CommonForm onSubmit={form.handleSubmit}>
      <Stack
        sx={{
          mb: 4,
        }}
        direction="row">
        <Box sx={{flex: 2, pt: 0, pr: 5}}>
          <Typography variant="h6" sx={{mb: 4, fontWeight: 'bold'}}>
            Question
          </Typography>

          <Box>
            <FormRow>
              <TextField
                formField="title"
                onChange={form.handleFieldChange('title')}
                value={form.values.title}
                label="Question"
              />
              <ErrorMessage
                name="title"
                errors={form.errors}
                touched={form.touched}
              />
            </FormRow>
          </Box>
        </Box>
        <Box sx={{flex: 1, mr: 4}}>
          <Typography variant="h6" sx={{mb: 4, fontWeight: 'bold'}}>
            Categories
          </Typography>

          <FormRow>
            <Stack flexDirection="column">
              <FieldArray
                container={Stack}
                containerProps={{
                  direction: 'column',
                }}
                formField="categories"
                onAdd={form.handleAddItem('categories')}
                onChange={form.handleFieldChange('categories')}
                onRemove={form.handleRemoveItem('categories')}
                onUpdate={form.handleUpdateItem('categories')}
                value={form.values.categories}
                // eslint-disable-next-line react-hooks/exhaustive-deps
                renderFooter={useCallback(({addItem}) => (
                  <Stack alignItems="flex-start">
                    <StyledAddButton
                      testId="add-category-button"
                      name={IconNames.PlusCircle}
                      size={36}
                      onClick={() => addItem({id: ''})}
                    />
                  </Stack>
                ))}
                // eslint-disable-next-line react-hooks/exhaustive-deps
                renderItem={useCallback(
                  (item, {index, removeItem, updateItem}) => (
                    <Fragment key={`category-${index}`}>
                      <Stack direction="row" alignItems="center" mb={2}>
                        <Select
                          data-testid="select-category"
                          items={resourceStore.questionCategories.map(
                            (cat) => ({
                              value: cat.id,
                              label: cat.name,
                            }),
                          )}
                          containerProps={{
                            fullWidth: true,
                          }}
                          formField="category.id"
                          value={item.id}
                          label="Categories"
                          onChange={(e) => updateItem({id: e.target.value})}
                        />
                        <StyledMinusButton
                          name={IconNames.Minus}
                          size={20}
                          onClick={removeItem}
                        />
                      </Stack>
                      <ErrorMessage
                        name={`categories.${index}.id`}
                        errors={form.errors}
                        touched={form.touched}
                      />
                    </Fragment>
                  ),
                )}
              />
            </Stack>
            {isString(form.errors.categories) && (
              <ErrorMessage
                name="categories"
                errors={form.errors}
                touched={form.touched}
              />
            )}
          </FormRow>
        </Box>
        <Box sx={{flex: 1}}>
          <Typography variant="h6" sx={{mb: 4, fontWeight: 'bold'}}>
            Phases
          </Typography>

          <FormRow>
            <Stack flexDirection="column">
              <FieldArray
                container={Stack}
                containerProps={{
                  direction: 'column',
                }}
                formField="phases"
                onAdd={form.handleAddItem('phases')}
                onChange={form.handleFieldChange('phases')}
                onRemove={form.handleRemoveItem('phases')}
                onUpdate={form.handleUpdateItem('phases')}
                value={form.values.phases}
                // eslint-disable-next-line react-hooks/exhaustive-deps
                renderFooter={useCallback(({addItem}) => (
                  <Stack alignItems="flex-start">
                    <StyledAddButton
                      testId="add-phase-button"
                      name={IconNames.PlusCircle}
                      size={36}
                      onClick={() => addItem({id: ''})}
                    />
                  </Stack>
                ))}
                // eslint-disable-next-line react-hooks/exhaustive-deps
                renderItem={useCallback(
                  (item, {index, removeItem, updateItem}) => (
                    <Fragment key={`phase-${index}`}>
                      <Stack direction="row" alignItems="center" mb={2}>
                        <Select
                          data-testid="select-phase"
                          items={resourceStore.questionPhases.map((phase) => ({
                            value: phase.id,
                            label: phase.name,
                          }))}
                          containerProps={{
                            fullWidth: true,
                          }}
                          formField="phases.id"
                          value={item.id}
                          label="Phases"
                          onChange={(e) => updateItem({id: e.target.value})}
                        />
                        <StyledMinusButton
                          name={IconNames.Minus}
                          size={20}
                          onClick={removeItem}
                        />
                      </Stack>
                      <ErrorMessage
                        name={`phases.${index}.id`}
                        errors={form.errors}
                        touched={form.touched}
                      />
                    </Fragment>
                  ),
                )}
              />
            </Stack>
            {isString(form.errors.phases) && (
              <ErrorMessage
                name="phases"
                errors={form.errors}
                touched={form.touched}
              />
            )}
          </FormRow>
        </Box>
      </Stack>
      <Button
        color="success"
        disabled={isSubmitting || hasErrors}
        variant="contained"
        type="submit">
        {isSubmitting ? 'Saving...' : 'Save Changes'}
      </Button>
    </CommonForm>
  );
};

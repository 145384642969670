// @flow
import type {RouteType} from 'types';
import {Detail} from './Detail';
import {List} from './List';
import {New} from './New';

export const routes: RouteType[] = [
  {
    Component: List,
    isProtected: true,
    index: true,
  },
  {
    Component: Detail,
    isProtected: true,
    path: ':id',
  },
  {
    Component: New,
    isProtected: true,
    path: 'new',
  },
];

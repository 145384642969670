// @flow
import React, {useEffect, useState} from 'react';
import type {ComponentType, Node} from 'react';
import {observer} from 'mobx-react';
import {Link} from 'react-router-dom';
import {useStores} from 'hooks/useStores';
import {
  DataGrid,
  // $FlowFixMe
} from '@wellstone-solutions/web';
// $FlowFixMe
import type {GridColDef} from '@mui/x-data-grid';
import {UNIVERSAL_LOCATION} from 'modules/app';
import {DATE, DATE_TIME} from 'constants/dateFormats';
import {formatDate} from 'utils/date';
import {StateChip} from 'components/StateChip';

const filterAnswers = (answers, questionId, location) =>
  answers.filter(
    (answer) =>
      answer.question.id === questionId &&
      [location, UNIVERSAL_LOCATION].includes(answer.state),
  );

type PropsType = {
  questionId: string,
};

export const AnswersGrid: ComponentType<PropsType> = observer(
  ({questionId}: PropsType): Node => {
    const [answers, setAnswers] = useState([]);
    const stores = useStores();
    const {resourceStore, resourcesUIStore} = stores;

    const question = resourceStore.questions.find((q) => q.id === questionId);

    useEffect(() => {
      const filteredAnswers = filterAnswers(
        resourceStore.answers,
        question.id,
        resourcesUIStore.location,
      );

      setAnswers(filteredAnswers);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [questionId, resourcesUIStore.location]);

    const columns: GridColDef[] = [
      {field: 'id', hide: true, headerName: ''},
      {
        field: 'state',
        headerName: 'State',
        renderCell: (props) => <StateChip state={props.value} />,
        width: 125,
      },
      {
        field: 'title',
        headerName: 'Title',
        flex: 3,
        renderCell: (props) => (
          <Link to={`/content-management/resources/answers/${props.id}`}>
            {props.value}
          </Link>
        ),
      },
      {
        field: 'contactSearch',
        headerName: 'Contacts',
        hide: true,
      },
      {
        field: 'organizationTitle',
        headerName: 'Organization Title',
        hide: true,
      },
      {
        field: 'expires',
        headerName: 'Expires',
        flex: 1,
        renderCell: (params) => formatDate(params.value, DATE),
      },
      {
        field: 'modified',
        headerName: 'Last Updated',
        flex: 1,
        renderCell: (params) => formatDate(params.value, DATE_TIME),
      },
    ];

    return (
      <DataGrid
        autoHeight
        rows={answers.slice()}
        columns={columns}
        isFilterable
        isPersisted
        initialColumnFilter="title"
      />
    );
  },
);

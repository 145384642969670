// @flow
import {
  FormControlLabel,
  Radio,
  RadioGroup as MUIRadioGroup,
} from '@mui/material';
import {Typography} from '@wellstone-solutions/web';
import type {Node} from 'react'

export type RadioGroupOptions = Array<{
    id: string,
    name: string,
    label: string,
    email: string,
  }>

type RadioGroupProps = {
  field: {
    value: string,
    onChange: Function,
  },
  options: RadioGroupOptions,
  label?: string,
};

export const RadioGroup = ({field, options}: RadioGroupProps): Node => {
  const {value, onChange} = field;

  return (
    <MUIRadioGroup
      sx={{
        maxHeight: '20rem',
        overflowY: 'auto',
        flexWrap: 'nowrap',
      }}
      aria-label="id"
      name="id"
      value={value}
      onChange={(event) => {
        onChange(event);
      }}>
      {options.map((option) => (
        <FormControlLabel
          key={option.id}
          value={option.id}
          control={<Radio />}
          label={
            <div>
              <Typography variant="subtitle1">
                <div>{option.name}</div>
              </Typography>
              <div>{option.email}</div>
            </div>
          }
        />
      ))}
    </MUIRadioGroup>
  );
};

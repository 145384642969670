// @flow
import type {ApiUserType} from '@wellstone-solutions/common/dist/models/types';
import type {RadioGroupOptions} from 'components/RadioGroup';
import type {Node} from 'react';

import {RadioForm} from './RadioForm';
import {Models} from '@wellstone-solutions/common';
import {Paper, Typography} from '@wellstone-solutions/web';

import {Search} from 'components/Search/Search';
import {useStores} from 'hooks/useStores';
import React, {useState} from 'react';

export const UpdateEmail = (): Node => {
  const [data, setData] = useState<RadioGroupOptions>([]);
  const {alertStore} = useStores();

  const handleSearchReset = () => {
    setData([]);
  };

  const handleSearch = async (searchTerm) => {
    const response = await Models.Super.User.findUser(searchTerm);
    if (response.isSuccess && response.data) {
      const data = response.data;
      const found =
        // $FlowFixMe This is the wrong type on the common libs side
        data.map((u: ApiUserType) => ({
          id: u.id,
          label: u.name,
          name: u.name,
          email: u.email,
        })) || [];
      setData(found);
    } else {
      alertStore.addError('Sorry. Having trouble with the search...');
    }
  };

  return (
    <Paper
      sx={{
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: 'auto',
      }}>
      <Typography
        variant="h5"
        sx={{
          mb: 2,
        }}>
        Update Email
      </Typography>
      <Search
        onSearch={handleSearch}
        ResultsComponent={() => (
          <RadioForm options={data} resetSearch={handleSearchReset} />
        )}
      />
    </Paper>
  );
};

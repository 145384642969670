// @flow
import {useEffect, useRef, useState} from 'react';
import type {ComponentType, Node} from 'react';
import {observer} from 'mobx-react';
import {useNavigate} from 'react-router-dom';
import {
  Box,
  Button,
  Stack,
  Typography,
  // $FlowFixMe
} from '@wellstone-solutions/web';
import {Hooks} from '@wellstone-solutions/common';
import {BackButton} from 'components/BackButton';
import {useStores} from 'hooks/useStores';
import {PATHFINDER_ORG_NAME} from '../constants';
import {Form} from './Form';
import {Preview} from './Preview';
import {AnnouncementModel} from '../models/announcement';
import {AdjacentList} from './AdjacentList';

const FORM_ID = 'new_form';

export const New: ComponentType<{}> = observer((): Node => {
  const contentRef = useRef();
  const navigate = useNavigate();
  const {alertStore, announcementStore, appUIStore} = useStores();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const form = Hooks.useForm({
    initialValues: AnnouncementModel.FormFactory({
      author: announcementStore.isRecipientOrganization
        ? ''
        : PATHFINDER_ORG_NAME,
    }),
    schema: AnnouncementModel.schema,
    onSubmit: async (values) => {
      setIsSubmitting(true);

      const organizations = announcementStore.isRecipientOrganization
        ? [{id: announcementStore.recipient}]
        : [];
      const state = announcementStore.isRecipientRegion
        ? announcementStore.recipient
        : '';

      const announcement = AnnouncementModel.toUIFromForm(
        AnnouncementModel.Factory({
          // $FlowFixMe
          organizations,
          state,
        }),
        values,
      );

      const response = await announcementStore.createAnnouncement(announcement);

      if (response.isSuccess) {
        alertStore.addSuccess('Created new announcement');

        if (response.data && response.data.id) {
          navigate(`../${response.data.id}`);
        }
      } else {
        alertStore.addError('Failed to create announcement. Please ensure an organization was selected.', response.errors);

        setIsSubmitting(false);
      }
    },
  });

  const isTouched = Object.keys(form.touched).length > 0;
  const hasErrors = Object.keys(form.errors).length > 0;

  useEffect(() => {
    if (isTouched) {
      appUIStore.setIsDirty(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTouched]);

  useEffect(() => {
    announcementStore.setAdjacentAnnouncements(form.values.scheduled);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.values.scheduled]);

  useEffect(
    () => () => appUIStore.setIsDirty(false),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <>
      <Stack sx={{mb: 2}} direction="row">
        <BackButton path="../../announcements" />
      </Stack>
      <Stack flexDirection="row" alignItems="flex-start">
        <Box flex={2}>
          <Typography variant="h6" sx={{mb: 4, fontWeight: 'bold'}}>
            New Announcement for {announcementStore.recipientName}
          </Typography>
          <Stack flexDirection="column">
            <Form form={form} formId={FORM_ID} ref={contentRef} />
          </Stack>
          <Box sx={{mt: 2}}>
            <Button
              color="success"
              disabled={isSubmitting || hasErrors || !isTouched}
              form={FORM_ID}
              variant="contained"
              type="submit">
              {isSubmitting ? 'Creating...' : 'Create'}
            </Button>
          </Box>
        </Box>
        <Box flex={1}>
          <Stack flexDirection="column" sx={{pl: 4}}>
            <Preview fields={{...form.values}} />
          </Stack>
        </Box>
      </Stack>
      <Stack flexDirection="column" sx={{mt: 4}}>
        <AdjacentList />
      </Stack>
    </>
  );
});

// @flow
// $FlowIgnore
import {Factories} from '@wellstone-solutions/common';
import type {FactoryOrganizationType, OrganizationType} from '../../types';

export const Factory = (
  overrides?: FactoryOrganizationType,
): OrganizationType =>
  Factories.createFactory(
    {
      id: '',
      name: '',
    },
    overrides,
  );

// @flow
import {Api} from '@wellstone-solutions/common';
import {routes} from 'api';
import type {ApiResponseType} from '@wellstone-solutions/common';
import type {APIAnswerType} from '../../types';

export const getAll = async (): Promise<APIAnswerType[]> => {
  const response = await Api.Instance.current().get(routes.resources.answers);

  return response.data.answers;
};

export const create = async (
  answer: APIAnswerType,
): Promise<ApiResponseType<APIAnswerType>> =>
  Api.Instance.current().post(routes.resources.answers, answer);

export const remove = async (id: string): Promise<ApiResponseType<void>> =>
  Api.Instance.current().delete(routes.resources.answer(id));

export const update = async (
  answer: APIAnswerType,
): Promise<ApiResponseType<APIAnswerType>> => {
  const {id, ...payload} = answer;

  return Api.Instance.current().put(
    `${routes.resources.answers}/${String(id)}`,
    payload,
  );
};

// @flow

import type {LabelValueType} from 'types';

export const status = {
  canceled: 'canceled',
  published: 'published',
  deleted: 'deleted',
  draft: 'draft',
};

export const recipientTypes: {
  allUsers: LabelValueType,
  byOrganization: LabelValueType,
  byRegion: LabelValueType,
} = {
  allUsers: {
    label: 'All Users',
    value: 'all_users',
  },
  byOrganization: {
    label: 'By Organization',
    value: 'by_org',
  },
  byRegion: {
    label: 'By Region',
    value: 'by_region',
  },
};

export const storageKeys: {
  recipientType: string,
  recipient: string,
  recipients: string,
} = {
  recipientType: 'announcements:recipientType',
  recipient: 'announcements:recipient',
  recipients: 'announcements:recipients',
};

export const adjacentAnnouncementsDays = 5;

export const PATHFINDER_ORG_NAME = 'Pathfinder';

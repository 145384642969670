// @flow
import * as React from 'react';
import type {Node} from 'react';
import {RootStore} from 'RootStore';

type PropsType = {
  children: React.Node,
  rootStore: RootStore,
};

export const StoresContext: React.Context<RootStore | void> =
  React.createContext();

export const StoresProvider = ({children, rootStore}: PropsType): Node => (
  <StoresContext.Provider value={rootStore}>{children}</StoresContext.Provider>
);

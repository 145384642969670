// @flow
import {useCallback, useEffect, useState} from 'react';
import type {ComponentType, Node} from 'react';
import {useParams} from 'react-router-dom';
// $FlowFixMe
import {Box, Progress, ProgressTypes} from '@wellstone-solutions/web';
import type {ApiResponseType} from '@wellstone-solutions/common';

type PropsType = {
  dataLoader: (string) => Promise<ApiResponseType<mixed>>,
  component: Node | ComponentType<{}>,
};

export const SuspendedComponent = ({
  dataLoader,
  component: Component,
}: PropsType): Node => {
  const params = useParams();
  const [preloadedData, setPreloadedData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = useCallback(async () => {
    const data = await dataLoader(params);
    setPreloadedData(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataLoader]);

  useEffect(() => {
    (async () => {
      await fetchData();
      setIsLoading(false);
    })();
  }, [fetchData]);

  return isLoading ? (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
      }}>
      <Progress color="secondary" progressType={ProgressTypes.circular} />
    </Box>
  ) : (
    // $FlowFixMe
    <Component fetchData={fetchData} preloadedData={preloadedData} />
  );
};

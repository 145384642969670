// @flow
import {Api} from '@wellstone-solutions/common';
import {routes} from 'api';
import type {ApiResponseType} from '@wellstone-solutions/common';
import type {APIResourceQuestionType} from '../../types';

export const getAll = async (): Promise<APIResourceQuestionType[]> => {
  const response = await Api.Instance.current().get(routes.resources.questions);

  return response.data.questions;
};

export const create = async (
  question: APIResourceQuestionType,
): Promise<ApiResponseType<APIResourceQuestionType>> =>
  Api.Instance.current().post(routes.resources.questions, question);

export const update = async (
  question: APIResourceQuestionType,
): Promise<ApiResponseType<APIResourceQuestionType>> => {
  const {id, ...payload} = question;

  if (!id) {
    return Promise.reject('Cannot Update: Question does not have an id');
  }

  return Api.Instance.current().put(
    `${routes.resources.questions}/${id}`,
    payload,
  );
};

// @flow
import type {APIAnnouncementType} from '../../types';
import {AnnouncementModel} from '../../models/announcement';

type ParamsType = {
  id: string,
};

export const dataLoader = async (
  params: ParamsType,
): Promise<APIAnnouncementType | null> => {
  const {id} = params;
  const response = await AnnouncementModel.get(id);

  return response.isSuccess ? response.data : null;
};

// @flow
import {Root} from 'modules/reporting';
import {NotFound} from 'components/NotFound';

export const reporting = [
  {
    Component: Root,
    isProtected: true,
    index: true,
    label: 'Reporting',
  },
  {
    Component: NotFound,
    isProtected: true,
    path: '*',
  },
];

// @flow
import React, {useCallback} from 'react';
import type {ComponentType, Node} from 'react';
import {observer} from 'mobx-react';
import {useStores} from 'hooks/useStores';
import {ToolbarSelect} from 'components/ToolbarSelect';
import {locations} from 'modules/app';

type PropsType = {
  onChange: (mixed) => void,
};

export const LocationSelect: ComponentType<PropsType> = observer(
  ({onChange}: PropsType): Node => {
    const {resourcesUIStore} = useStores();

    const locationChangeHandler = useCallback(
      (event) => {
        onChange(() => {
          const location = event.target.value;
          resourcesUIStore.setLocation(location);
        });
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [onChange],
    );

    return (
      <ToolbarSelect
        containerProps={{
          fullWidth: true,
          sx: {maxWidth: 200},
        }}
        canUnselect={false}
        renderValue={useCallback((selected) => {
          const selectedLocation = locations.find((d) => d.value === selected);

          return selectedLocation?.label;
        }, [])}
        items={locations}
        value={resourcesUIStore.location}
        onChange={locationChangeHandler}
      />
    );
  },
);

// @flow
// $FlowFixMe
import {Transforms} from '@wellstone-solutions/common';
import moment from 'moment';
import type {IObservableArray} from 'mobx';
import type {
  APIResourceQuestionType,
  FormResourceQuestionType,
  QuestionsDataGridType,
  UIAnswerType,
  UICategoryType,
  UIPhaseType,
  UIResourceQuestionType,
} from '../../types';
import {Factory} from './factories';

export const toDataGrid = (
  question: UIResourceQuestionType,
  answers: UIAnswerType[] | IObservableArray<UIAnswerType>,
  state: string,
): QuestionsDataGridType => {
  const categories = question.categories.map((c) => c.name).join(' ');
  const phases = question.phases.map((p) => p.name).join(' ');
  const questionsAnswers = answers.filter(
    (a) => a.question?.id === question.id && a.state === state,
  );
  const hasAnswer = questionsAnswers.length > 0 ? 'YES' : '';
  const lastAnswerUpdate = questionsAnswers.reduce((lastUpdate, answer) => {
    if (!lastUpdate || moment(lastUpdate).isBefore(answer.modified)) {
      lastUpdate = answer.modified;
    }
    return lastUpdate;
  }, undefined);

  return {
    id: question.id ? question.id : '',
    title: question.title,
    categories,
    modified: question.modified,
    phases,
    hasAnswer,
    lastAnswerUpdate,
  };
};

export const toUI = (
  question: APIResourceQuestionType,
  phaseMap: IObservableArray<UIPhaseType>,
  categoryMap: IObservableArray<UICategoryType>,
): UIResourceQuestionType => {
  const categories = question.groups.map((cat) =>
    Transforms.hydrate(cat, categoryMap),
  );
  const phases = question.phases.map((phase) =>
    Transforms.hydrate(phase, phaseMap),
  );

  const uiQuestion = Transforms.omitKeys(
    {
      ...question,
      categories,
      phases,
    },
    ['groups'],
  );

  return Factory(Transforms.toUIObject(uiQuestion));
};

export const toForm = (
  question: UIResourceQuestionType,
): FormResourceQuestionType => {
  const categories = question.categories.map((cat) => ({id: cat.id}));
  const phases = question.phases.map((phase) => ({id: phase.id}));

  const formQuestion = Transforms.omitKeys(
    {
      ...question,
      categories,
      phases,
    },
    ['groups'],
  );

  return formQuestion;
};

export const toAPI = (
  question: FormResourceQuestionType | UIResourceQuestionType,
): APIResourceQuestionType => {
  const groups = question.categories.map((cat) => ({id: cat.id}));
  const phases = question.phases.map((phase) => ({id: phase.id}));

  const keysToOmit = ['categories', 'modified'];
  if (question.id?.length === 0) {
    keysToOmit.push('id');
  }

  const apiQuestion = Transforms.omitKeys(
    {...question, groups, phases},
    keysToOmit,
  );

  return Transforms.toApiObject(apiQuestion);
};

// @flow
import React from 'react';
import {observer} from 'mobx-react';
import type {ComponentType, Node} from 'react';
// $FlowFixMe
import {CssBaseline, Box} from '@wellstone-solutions/web';
import {AppBar} from '../AppBar';
import {SideDrawer} from './SideDrawer';
import {useStores} from 'hooks/useStores';

type PropsType = {
  children: Node,
  appBarChildren?: Node,
};

export const LayoutDrawer: ComponentType<PropsType> = observer(
  ({children, appBarChildren}: PropsType): Node => {
    const {appUIStore} = useStores();
    const open = appUIStore.sideDrawerIsOpen;

    const toggleDrawer = () => {
      appUIStore.toggleSideDrawer();
    };

    return (
      <Box sx={{display: 'flex', minHeight: '100vh'}}>
        <CssBaseline />
        <AppBar open={open} toggleDrawer={toggleDrawer}>
          {appBarChildren}
        </AppBar>
        <SideDrawer open={open} toggleDrawer={toggleDrawer} />
        {children}
      </Box>
    );
  },
);

// @flow
import React from 'react';
import type {ComponentType, Node} from 'react';
import {observer} from 'mobx-react';
// $FlowFixMe
import {Snackbar} from '@wellstone-solutions/web';
import {useStores} from 'hooks/useStores';

export const AlertManager: ComponentType<{}> = observer((): Node => {
  const {alertStore} = useStores();

  return alertStore.alerts.map((alert) => (
    <Snackbar
      autoHideDuration={alert.duration}
      key={alert.id}
      message={alert.message}
      open={true}
      onClose={() => {
        alertStore.remove(alert.id);
      }}
      severity={alert.severity}
    />
  ));
});

// @flow
import type {Node} from 'react';
import {
  Button,
  // $FlowFixMe
} from '@wellstone-solutions/web';

type PropsType = {
  formId: string,
  handlePublishNow: () => void,
  handlePublishConfirmation: () => mixed,
  handleCancelPublish: () => void,
  handleRevertChanges: () => void,
  hasErrors: boolean,
  isPublishing: boolean,
  isScheduledForToday: boolean,
  isSubmitting: boolean,
  isTouched: boolean,
  recipientName: string,
};

export const PublishBarDraftButtons = ({
  formId,
  handlePublishNow,
  handlePublishConfirmation,
  handleCancelPublish,
  handleRevertChanges,
  hasErrors,
  isPublishing,
  isScheduledForToday,
  isSubmitting,
  isTouched,
  recipientName,
}: PropsType): Node => (
  <>
    <Button
      color="primary"
      disabled={hasErrors || !isTouched}
      form={formId}
      variant="contained"
      sx={{alignSelf: 'center'}}
      type="submit">
      {isSubmitting ? 'Updating...' : !isTouched ? 'Draft Saved' : 'Update'}
    </Button>
    {isTouched ? (
      <Button
        color="primary"
        onClick={handleRevertChanges}
        sx={{alignSelf: 'center', ml: 2}}
        variant="outlined"
        type="button">
        Revert Changes
      </Button>
    ) : null}
    {!isPublishing && !isTouched && isScheduledForToday ? (
      <Button
        color="success"
        onClick={handlePublishNow}
        variant="contained"
        sx={{alignSelf: 'center', ml: 2}}
        type="button">
        Publish Now
      </Button>
    ) : null}
    {isPublishing ? (
      <>
        <Button
          color="success"
          disabled={isSubmitting}
          onClick={handlePublishConfirmation}
          variant="contained"
          sx={{alignSelf: 'center', ml: 2}}
          type="button">
          {`Confirm & Publish to ${recipientName}`}
        </Button>
        <Button
          color="success"
          onClick={handleCancelPublish}
          variant="outlined"
          sx={{alignSelf: 'center', ml: 2}}
          type="button">
          Cancel Publish
        </Button>
      </>
    ) : null}
  </>
);

// @flow
import React, {useCallback, useEffect, useRef, useState} from 'react';
import type {Node} from 'react';
import {useNavigate} from 'react-router-dom';
import {
  Box,
  Button,
  Divider,
  FieldArray,
  Form as CommonForm,
  FormRow,
  Icon,
  IconNames,
  ErrorMessage,
  RichTextEditor,
  TextField,
  Typography,
  Select,
  Stack,
  // $FlowFixMe
} from '@wellstone-solutions/web';
// $FlowFixMe
import {Hooks, Models, Theme} from '@wellstone-solutions/common';
import type {ApiResponseType} from '@wellstone-solutions/common';
import {useStores} from 'hooks/useStores';
import {StateSelect} from 'components/StateSelect';
import {AnswerModel} from '../../../models/answer';
import type {APIAnswerType, UIAnswerType} from '../../../types';
import {Preview} from './Preview';
import {ContactItem} from './ContactItem';

const {ResourceContact} = Models;

type PropsType = {
  isNew?: boolean,
  answer: UIAnswerType,
  onSave: (answer: UIAnswerType) => Promise<ApiResponseType<APIAnswerType>>,
};

const AVG_DEVICE_WIDTH = '320px';

const mapContactError = (
  error: string,
  values,
): {field: string, message: string} => {
  const fieldParts = error.split('.');
  const contactTitle =
    values.contacts[fieldParts[1]]?.title || `Contact #${fieldParts[1]}`;
  return {
    field: contactTitle,
    message: `has invalid ${fieldParts[2]}`,
  };
};

const mapErrors = (errors, values) =>
  errors.map((error) => {
    if (typeof error === 'object') {
      const fieldError = error.field || Object.keys(error)[0];

      if (fieldError.startsWith('contacts')) {
        return mapContactError(fieldError, values);
      }
    }

    return error;
  });

export const Form = ({isNew = false, answer, onSave}: PropsType): Node => {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {alertStore, appUIStore, resourcesUIStore, resourceStore} = useStores();
  const questions = resourceStore.questions
    .map((question) => ({
      value: question.id,
      label: question.title,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const handleCancel = useCallback(() => {
    navigate(-1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDelete = useCallback(async () => {
    const isConfirmed = window.confirm(
      'Are you sure you want to delete this answer?',
    );

    if (isConfirmed) {
      const response = await resourceStore.removeAnswer(String(answer.id));

      if (response.isSuccess) {
        navigate(-1);
      } else {
        alertStore.addError('Failed to delete this answer');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const form = Hooks.useForm({
    initialValues: answer,
    schema: AnswerModel.schema,
    onSubmit: async (values, {resetForm}) => {
      setIsSubmitting(true);

      const response = await onSave(values);

      if (response.isSuccess) {
        if (isNew) {
          alertStore.addSuccess('Successfully saved new answer.');
          resetForm();
          resetDescription();
        } else {
          alertStore.addSuccess('Successfully updated answer.');
        }

        if (response.messages && response.messages.length > 0) {
          const errorMessages = mapErrors(response.messages, values);

          alertStore.addWarning(
            'Saved answer, but with some warnings to consider',
            errorMessages,
          );
        }
      } else {
        form.handleApiErrors(response.errors);
        // Map contact title to errors for more useful error messages
        const errorMessages = mapErrors(response.errors, values);

        alertStore.addError('Failed to save answer', errorMessages);
      }

      setIsSubmitting(false);
    },
  });

  const descriptionRef = useRef();

  const handleDescriptionChange = useCallback(() => {
    // $FlowFixMe
    const content = descriptionRef.current.getInstance().getMarkdown();
    form.setFieldTouched('description');
    form.setFieldValue('description', content);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetDescription = useCallback(() => {
    // $FlowFixMe
    descriptionRef.current.getInstance().setMarkdown('');
  }, []);

  const isTouched = Object.keys(form.touched).length > 0;
  const hasErrors = Object.keys(form.errors).length > 0;

  useEffect(() => {
    if (isTouched) {
      appUIStore.setIsDirty(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTouched]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => appUIStore.setIsDirty(false), []);

  return (
    <Stack flexDirection="row" alignItems="flex-start">
      <Box flex={2}>
        <Stack flexDirection="column">
          <CommonForm onSubmit={form.handleSubmit}>
            <FormRow>
              <Stack
                direction={{xs: 'column-reverse', lg: 'row'}}
                spacing={{xs: 4, lg: 0}}
                justifyContent="space-between"
                sx={{mb: 4}}>
                <Stack flexDirection="row">
                  <TextField
                    formField="expires"
                    type="date"
                    onChange={form.handleFieldChange('expires')}
                    value={form.values.expires.slice(0, 10)}
                    label="Expiration Date"
                    sx={{mr: 1}}
                  />
                  <StateSelect
                    formField="state"
                    onChange={form.handleFieldChange('state')}
                    value={form.values.state}
                  />
                </Stack>
                <Stack alignItems="center" flexDirection="row">
                  <Button
                    variant="contained"
                    color="success"
                    disabled={isSubmitting || hasErrors}
                    type="submit"
                    sx={{alignSelf: 'center', whiteSpace: 'nowrap', mr: 1}}>
                    {isSubmitting ? 'Saving...' : 'Save Changes'}
                  </Button>
                  <Button
                    variant="outlined"
                    color="success"
                    onClick={handleCancel}
                    sx={{alignSelf: 'center', minWidth: '120px'}}>
                    Cancel
                  </Button>
                  {!isNew && (
                    <Box
                      sx={{
                        ml: 2,
                        pl: 2,
                        borderLeft: `solid 1px ${Theme.colorPalette.mediumDark}`,
                      }}>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={handleDelete}
                        sx={{
                          alignSelf: 'center',
                          minWidth: '120px',
                        }}>
                        Delete
                      </Button>
                    </Box>
                  )}
                </Stack>
              </Stack>
            </FormRow>
            <Typography variant="h5">Answers the question...</Typography>
            <FormRow>
              <Select
                data-testid="select-question"
                formField="question.id"
                onChange={form.handleFieldChange('question.id')}
                value={form.values.question.id}
                containerProps={{
                  fullWidth: true,
                }}
                items={questions}
                label="Questions"
              />
              <ErrorMessage
                name="questionId"
                errors={form.errors}
                touched={form.touched}
              />
            </FormRow>
            <FormRow>
              <TextField
                formField="title"
                onChange={form.handleFieldChange('title')}
                value={form.values.title}
                label="Title"
              />
              <ErrorMessage
                name="title"
                errors={form.errors}
                touched={form.touched}
              />
            </FormRow>
            <FormRow>
              <TextField
                formField="subtitle"
                onChange={form.handleFieldChange('subtitle')}
                value={form.values.subtitle}
                label="Subtitle"
              />
            </FormRow>
            <FormRow>
              <Typography variant="h7" sx={{fontWeight: 'bold', mb: 1}}>
                Full Description
              </Typography>
              <RichTextEditor
                initialValue={form.values.description}
                previewStyle="vertical"
                height="300px"
                usageStatistic={false}
                hideModeSwitch={false}
                initialEditType="wysiwyg"
                useCommandShortcut={true}
                toolbarItems={[
                  [
                    'heading',
                    'bold',
                    'italic',
                    'hr',
                    'ul',
                    'ol',
                    'quote',
                    'link',
                  ],
                ]}
                onChange={handleDescriptionChange}
                // $FlowFixMe
                ref={descriptionRef}
              />
            </FormRow>
            <FormRow>
              <TextField
                data-testid="organization"
                formField="organizationTitle"
                onChange={form.handleFieldChange('organizationTitle')}
                value={form.values.organizationTitle}
                label="Organization Title"
              />
              <ErrorMessage
                name="organizationTitle"
                errors={form.errors}
                touched={form.touched}
              />
            </FormRow>
            <FormRow>
              <Typography
                variant="h6"
                sx={{
                  color: Theme.colorPalette.blue,
                  fontWeight: 'bold',
                  mt: 2,
                }}>
                Contact
              </Typography>
              <FieldArray
                container={Stack}
                formField="contacts"
                onAdd={form.handleAddItem('contacts')}
                onChange={form.handleFieldChange('contacts')}
                onRemove={form.handleRemoveItem('contacts')}
                onUpdate={form.handleUpdateItem('contacts')}
                value={form.values.contacts || []}
                renderFooter={useCallback(
                  ({addItem}) => (
                    <Stack alignItems="flex-end" mt={1}>
                      <Button
                        onClick={() => addItem(ResourceContact.Factory())}>
                        <Icon name={IconNames.PlusCircle} />
                      </Button>
                    </Stack>
                  ),
                  [],
                )}
                renderItem={useCallback(
                  (item, {index, removeItem, updateItem}) => (
                    <React.Fragment key={`contact-${index}`}>
                      {index > 0 && (
                        <Divider
                          light={false}
                          sx={{
                            backgroundColor: Theme.colorPalette.secondary,
                            my: 4,
                          }}
                        />
                      )}
                      <ContactItem
                        errors={form.errors}
                        apiErrors={form.status.apiErrors}
                        touched={form.touched}
                        item={item}
                        index={index}
                        removeItem={removeItem}
                        updateItem={updateItem}
                      />
                    </React.Fragment>
                  ),
                  [form.errors, form.touched, form.status],
                )}
              />
            </FormRow>
          </CommonForm>
        </Stack>
      </Box>
      <Box flex={1} sx={{maxWidth: AVG_DEVICE_WIDTH}}>
        <Stack
          flexDirection="column"
          sx={{
            pl: 4,
            pt: 4,
            mt: 7,
            position: 'fixed',
            right: {
              xs: 20,
              lg: 'unset',
            },
            zIndex: 999,
            width: AVG_DEVICE_WIDTH,
          }}>
          <Preview
            fields={{...form.values, location: resourcesUIStore.location}}
          />
        </Stack>
      </Box>
    </Stack>
  );
};

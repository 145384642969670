// @flow
import React, {useCallback} from 'react';
import type {ComponentType, Node} from 'react';
import {observer} from 'mobx-react';
import {useStores} from 'hooks/useStores';
import {ToolbarSelect} from 'components/ToolbarSelect';
import {categories} from '../../constants';

const PLACEHOLDER = 'Select Category';

type PropsType = {
  onChange: (mixed) => void,
};

export const CategorySelect: ComponentType<PropsType> = observer(
  ({onChange}: PropsType): Node => {
    const {resourcesUIStore} = useStores();

    const handleCategoryChange = useCallback(
      (event) => {
        onChange(() => {
          resourcesUIStore.setCategory(event.target.value);
        });
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [onChange],
    );

    const renderValue = useCallback((selected) => {
      const selectedCategory = categories.find((d) => d.value === selected);

      return selectedCategory ? selectedCategory.label : PLACEHOLDER;
    }, []);

    // Hide if only 1 category
    if (categories.length <= 1) {
      return null;
    }

    return (
      <ToolbarSelect
        containerProps={{
          fullWidth: true,
          sx: {maxWidth: 200},
        }}
        displayEmpty
        renderValue={renderValue}
        value={resourcesUIStore.category}
        items={categories}
        onChange={handleCategoryChange}
      />
    );
  },
);

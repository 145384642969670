// @flow
import type {Node} from 'react';
// $FlowFixMe
import {Chip} from '@wellstone-solutions/web';
import {UNIVERSAL_LOCATION} from 'modules/app';

type PropsType = {
  state: string,
};

const getColorForState = (state) =>
  state === UNIVERSAL_LOCATION ? 'info' : 'success';

export const StateChip = ({state}: PropsType): Node => (
  <Chip
    variant="filled"
    label={state.toUpperCase()}
    color={getColorForState(state)}
  />
);

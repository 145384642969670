// @flow
import React from 'react';
import type {Node, ComponentType} from 'react';
// $FlowFixMe
import {Stack, Typography} from '@wellstone-solutions/web';

type PropsType = {
  delimiter?: string,
  textComponent?: ComponentType<{children: any}>,
  value?: string,
  ...
};

export const DelimiterToStack = ({
  delimiter = '\n',
  textComponent: TextComponent = Typography,
  value,
  ...rest
}: PropsType): Node => {
  if (!value) return null;

  const parts = value.split(delimiter);

  return (
    <Stack {...rest}>
      {parts.map((part, index) => (
        <TextComponent key={`newline-${part?.length}-${index}`}>
          {part}
        </TextComponent>
      ))}
    </Stack>
  );
};

// @flow
// $FlowIgnore
import {Factories} from '@wellstone-solutions/common';
import type {FactoryPhaseType, IdObjectType, UIPhaseType} from '../../types';

export const Factory = (overrides?: FactoryPhaseType): UIPhaseType =>
  Factories.createFactory(
    {
      id: '',
      name: '',
    },
    overrides,
  );

export const FormFactory = (overrides?: FactoryPhaseType): IdObjectType =>
  Factories.createFactory(
    {
      id: '',
    },
    overrides,
  );

// @flow
import type {Node, ComponentType} from 'react';

import InputAdornment from '@mui/material/InputAdornment';
import {Utils} from '@wellstone-solutions/common';
import {Icon, Progress, Stack, TextField} from '@wellstone-solutions/web';

import React, {useState, useEffect, useCallback
} from 'react';

type PropsType = {
  inputProps?: any,
  label?: string,
  onSearch: (string) => any,
  ResultsComponent: ComponentType<any>,
  testId?: string,
};

const WAIT = 400;

export const Search = ({
  inputProps = {
    startAdornment: (
      <InputAdornment position="start">
        <Icon name="Search" />
      </InputAdornment>
    ),
  },
  label = 'Search',
  onSearch,
  ResultsComponent,
  ...rest
}: PropsType): Node => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [results, setResults] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSearchChange = useCallback((event) => {
    setSearchTerm(event.target.value);
  }, []);

  const handleReset = useCallback(() => {
    setSearchTerm('');
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    Utils.debouncePromise(onSearch, WAIT),
    [],
  );

  useEffect(() => {
    if (searchTerm.length > 2) {
      setIsLoading(true);
      debouncedSearch(searchTerm).then((response) => {
        setResults(response?.isSuccess ? response.data : null);
        setIsLoading(false);
      });
    } else {
      setResults({});
      setIsLoading(false);
    }
  }, [debouncedSearch, searchTerm]);

  return (
    <Stack sx={{position: 'relative'}}>
      <TextField
        {...rest}
        InputProps={{
          ...inputProps,
          endAdornment: isLoading ? (
            <Progress size="20px" data-testid="search-loader" />
          ) : (
            inputProps.endAdornment
          ),
        }}
        label={label}
        onChange={handleSearchChange}
        type={isLoading ? undefined : 'search'} // hides the clear button while loading
        value={searchTerm}
      />
      <ResultsComponent results={results} resetSearch={handleReset} />
    </Stack>
  );
};

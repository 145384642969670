// @flow
import type {Node} from 'react';
import {
  Alert,
  Stack,
  // $FlowFixMe
} from '@wellstone-solutions/web';
import {PublishBarDraftButtons} from './PublishBarDraftButtons';
import {PublishBarPublishedButtons} from './PublishBarPublishedButtons';

type PropsType = {
  formId: string,
  handlePublishNow: () => void,
  handlePublishConfirmation: () => mixed,
  handleCancelPublish: () => void,
  handleUnpublish: () => mixed,
  handleConfirmUnpublish: () => mixed,
  handleCancelUnpublish: () => void,
  handleRevertChanges: () => void,
  hasErrors: boolean,
  isDraft: boolean,
  isPublished: boolean,
  isPublishing: boolean,
  isUnpublishing: boolean,
  isScheduledForToday: boolean,
  isSubmitting: boolean,
  isTouched: boolean,
  recipientName: string,
};

export const PublishButtonBar = ({
  formId,
  handlePublishNow,
  handlePublishConfirmation,
  handleCancelPublish,
  handleUnpublish,
  handleConfirmUnpublish,
  handleCancelUnpublish,
  handleRevertChanges,
  hasErrors,
  isDraft,
  isPublished,
  isPublishing,
  isUnpublishing,
  isScheduledForToday,
  isSubmitting,
  isTouched,
  recipientName,
}: PropsType): Node => (
  <Stack flexDirection="row" sx={{mt: 2}}>
    {isDraft && (
      <PublishBarDraftButtons
        formId={formId}
        handlePublishNow={handlePublishNow}
        handlePublishConfirmation={handlePublishConfirmation}
        handleCancelPublish={handleCancelPublish}
        handleRevertChanges={handleRevertChanges}
        hasErrors={hasErrors}
        isPublishing={isPublishing}
        isScheduledForToday={isScheduledForToday}
        isSubmitting={isSubmitting}
        isTouched={isTouched}
        recipientName={recipientName}
      />
    )}
    {isPublished && (
      <PublishBarPublishedButtons
        handleConfirmUnpublish={handleConfirmUnpublish}
        handleCancelUnpublish={handleCancelUnpublish}
        handleUnpublish={handleUnpublish}
        isUnpublishing={isUnpublishing}
        isSubmitting={isSubmitting}
        recipientName={recipientName}
      />
    )}
    {!isScheduledForToday && (
      <Alert sx={{ml: 2}} severity="info">
        Schedule for today to publish
      </Alert>
    )}
  </Stack>
);

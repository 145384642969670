// @flow
// $FlowIgnore
import {Factories} from '@wellstone-solutions/common';
import type {
  FactoryCategoryType,
  IdObjectType,
  UICategoryType,
} from '../../types';

export const Factory = (overrides?: FactoryCategoryType): UICategoryType =>
  Factories.createFactory(
    {
      id: '',
      name: '',
    },
    overrides,
  );

export const FormFactory = (overrides?: FactoryCategoryType): IdObjectType =>
  Factories.createFactory(
    {
      id: '',
    },
    overrides,
  );

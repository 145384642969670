// @flow
import type {Node} from 'react';
import {
  Button,
  // $FlowFixMe
} from '@wellstone-solutions/web';

type PropsType = {
  handleConfirmUnpublish: () => mixed,
  handleCancelUnpublish: () => void,
  handleUnpublish: () => mixed,
  isUnpublishing: boolean,
  isSubmitting: boolean,
  recipientName: string,
};

export const PublishBarPublishedButtons = ({
  handleConfirmUnpublish,
  handleCancelUnpublish,
  handleUnpublish,
  isUnpublishing,
  isSubmitting,
  recipientName,
}: PropsType): Node => (
  <>
    <Button
      color="success"
      disabled={true}
      variant="contained"
      type="button"
      sx={{alignSelf: 'center'}}>
      Published
    </Button>
    {isUnpublishing ? (
      <>
        <Button
          color="primary"
          disabled={isSubmitting}
          onClick={handleConfirmUnpublish}
          variant="contained"
          sx={{alignSelf: 'center', ml: 2}}
          type="button">
          Confirm & Revert to Draft
        </Button>
        <Button
          color="primary"
          onClick={handleCancelUnpublish}
          variant="outlined"
          sx={{alignSelf: 'center', ml: 2}}
          type="button">
          Cancel
        </Button>
      </>
    ) : (
      <Button
        color="primary"
        disabled={isSubmitting}
        onClick={handleUnpublish}
        sx={{alignSelf: 'center', ml: 2}}
        variant="contained"
        type="button">
        {`Unpublish from ${recipientName}`}
      </Button>
    )}
  </>
);

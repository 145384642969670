// @flow
import React, {useCallback} from 'react';
import type {ComponentType, Node} from 'react';
import {observer} from 'mobx-react';
import {useStores} from 'hooks/useStores';
import {ToolbarSelect} from 'components/ToolbarSelect';
import {recipientTypes} from '../../constants';

type PropsType = {
  onChange: (mixed) => void,
};

export const RecipientTypeSelect: ComponentType<PropsType> = observer(
  ({onChange}: PropsType): Node => {
    const {announcementStore} = useStores();
    const recipientTypesValues = Object.keys(recipientTypes).map(
      (key) => recipientTypes[key],
    );

    const handleChange = useCallback(
      (event) => {
        onChange(() => {
          announcementStore.setRecipientType(event.target.value);

          // When we set announcements to "All users" the 3rd dropdown, i.e.
          // the recipient is not displayed. We handle that case here by
          // getting announcements for all users.
          if (announcementStore.isRecipientAllUsers) {
            announcementStore.getAnnouncements();
          }
        });
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [onChange],
    );

    return (
      <ToolbarSelect
        canUnselect={false}
        containerProps={{
          fullWidth: true,
          sx: {maxWidth: 200},
        }}
        renderValue={useCallback((selected) => {
          const selectedItem = recipientTypesValues.find(
            (d) => d.value === selected,
          );

          return selectedItem?.label;
          // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])}
        items={recipientTypesValues}
        value={announcementStore.recipientType}
        onChange={handleChange}
      />
    );
  },
);

// @flow
import React, {useCallback} from 'react';
import type {ComponentType, Node} from 'react';
import {observer} from 'mobx-react';
import {useStores} from 'hooks/useStores';
import {ToolbarSelect} from 'components/ToolbarSelect';
import {domains} from '../../constants';

type PropsType = {
  onChange: (mixed) => void,
};

export const DomainSelect: ComponentType<PropsType> = observer(
  ({onChange}: PropsType): Node => {
    const {contentManagementUIStore} = useStores();
    const domainValues = Object.values(domains);

    const handleDomainChange = useCallback(
      (event) => {
        onChange(() => {
          contentManagementUIStore.setDomain(event.target.value);
        });
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [onChange],
    );

    return (
      <ToolbarSelect
        containerProps={{
          fullWidth: true,
          sx: {maxWidth: 200},
        }}
        canUnselect={false}
        renderValue={useCallback((selected) => {
          const domain = domainValues.find(
            (d) => typeof d === 'object' && d?.value === selected,
          );

          if (typeof domain !== 'object') {
            return '';
          }

          return domain?.label;
          // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])}
        items={domainValues}
        value={contentManagementUIStore.domain}
        onChange={handleDomainChange}
      />
    );
  },
);

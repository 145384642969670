// @flow
import type {RoleType} from '@wellstone-solutions/common';
import type {Node} from 'react';

import {RadioForm} from './RadioForm';
import {Models, Transforms} from '@wellstone-solutions/common';
import {Paper, Typography} from '@wellstone-solutions/web';

import {Search} from 'components/Search/Search';
import React, {useState} from 'react';

type MemberType = {
  memberId: string,
  role: RoleType,
  userName: string,
  userEmail: string,
  organizationName: string,
};

export const UpdateRoles = (): Node => {
  const [data, setData] = useState<MemberType[]>([]);

  const handleSearch = async (searchTerm) => {
    const response = await Models.Super.Member.findMembers(searchTerm);

    if (response.isSuccess) {
      const uiMembers = ((response.data: any): Array<MemberType>).map(
        Transforms.toUIObject,
      );
      setData(uiMembers);
    }
  };

  return (
    <Paper
      sx={{
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: 'auto',
      }}>
      <Typography
        variant="h5"
        sx={{
          mb: 2,
        }}>
        Change Staff Role
      </Typography>
      <Search
        onSearch={handleSearch}
        ResultsComponent={() => (
          <RadioForm
            options={data}
            resetSearch={() => {
              setData([]);
            }}
          />
        )}
      />
    </Paper>
  );
};

// @flow
import {Root} from 'modules/dashboard';
import {NotFound} from 'components/NotFound';

export const dashboard = [
  {
    Component: Root,
    isProtected: true,
    index: true,
    label: 'Dashboard',
  },
  {
    Component: NotFound,
    isProtected: true,
    path: '*',
  },
];

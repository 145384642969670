// @flow
import type {Node} from 'react';
import {styled} from '@mui/material/styles';
// $FlowFixMe
import {Select} from '@wellstone-solutions/web';
import {locations, locationStates} from 'modules/app';

type PropsType = {
  formField?: string,
  label?: string,
  onChange: mixed,
  value?: string,
  universal?: boolean,
  ...
};

const StyledSelect = styled(Select)(() => ({
  minWidth: '150px',
}));

const DEFAULT_LABEL = 'State';

export const StateSelect = ({
  formField,
  label = DEFAULT_LABEL,
  onChange,
  value,
  universal = true,
  ...rest
}: PropsType): Node => (
  <StyledSelect
    {...rest}
    formField={formField}
    onChange={onChange}
    value={value}
    items={universal ? locations : locationStates}
    label={label}
  />
);

// @flow
import moment from 'moment';
// $FlowFixMe
import {Transforms} from '@wellstone-solutions/common';
import type {NameIdType} from 'types';
import type {
  AdjacentAnnouncementsDataGridType,
  AnnouncementsCalendarType,
  AnnouncementsDataGridType,
  APIAnnouncementType,
  FormAnnouncementType,
  RecipientType,
  UIAnnouncementType,
} from '../../types';
import {Factory, FormFactory} from './factories';
import {recipientTypes} from '../../constants';

export const toDataGrid = (
  announcement: UIAnnouncementType,
): AnnouncementsDataGridType => {
  const {id, publishedOn, scheduled, status, state, title} = announcement;
  return {
    id: String(id),
    publishedOn,
    scheduled,
    state,
    status,
    title,
  };
};

export const toCalendarEvent = (
  announcement: UIAnnouncementType,
): AnnouncementsCalendarType => {
  const {id, publishedOn, scheduled, status, state, title} = announcement;

  const safeTitle = !title || title.length === 0 ? 'No title' : title;

  return {
    title: safeTitle,
    start: moment(scheduled),
    end: moment(scheduled),
    allDay: true,
    resource: {
      id,
      status,
      state,
      publishedOn,
    },
  };
};

export const toAdjacentDataGrid = (
  announcement: UIAnnouncementType,
): AdjacentAnnouncementsDataGridType => {
  const {id, recipient, scheduled, status, title} = announcement;

  return {
    id: String(id),
    recipient: recipient,
    scheduled: String(scheduled),
    status,
    title,
  };
};

export const toUI = (
  announcement: APIAnnouncementType,
  allOrganizations: NameIdType[],
): UIAnnouncementType => {
  const {organizations} = announcement;
  const uniformOrgs =
    organizations && organizations.length > 0 ? organizations : [];

  const hydratedOrgs: NameIdType[] = uniformOrgs.map((org) =>
    Transforms.hydrate(org, allOrganizations),
  );

  const recipient: RecipientType = buildUIRecipient(
    announcement.state,
    hydratedOrgs,
  );

  const uiAnnouncement: UIAnnouncementType = Transforms.toUIObject(
    Transforms.omitKeys(
      {
        ...announcement,
        organizations: hydratedOrgs.map(Transforms.toUIObject),
        recipient,
      },
      [
        'created',
        'created_by',
        'group',
        'group_id',
        'organization',
        'modified',
      ],
    ),
  );

  return Factory(uiAnnouncement);
};

export const toForm = (
  announcement: UIAnnouncementType,
): FormAnnouncementType => {
  const formAnnouncement = Transforms.omitKeys(announcement, [
    'group',
    'publishedOn',
    'created',
    'modified',
    'organizations',
    'recipient',
    'state',
  ]);

  return FormFactory(formAnnouncement);
};

export const toUIFromForm = (
  uiAnnouncement: UIAnnouncementType,
  formAnnouncement: FormAnnouncementType,
): UIAnnouncementType => {
  return Factory(Object.assign(uiAnnouncement, formAnnouncement));
};

export const toAPI = (
  announcement: UIAnnouncementType,
): APIAnnouncementType => {
  const keysToOmit = [
    'created',
    'group',
    'modified',
    'publishedOn',
    'recipient',
  ];

  const organizations = announcement.organizations.map((announcement) => ({
    id: announcement.id,
  }));

  if (announcement.id?.length === 0) {
    keysToOmit.push('id');
  }

  if (!announcement.scheduled || announcement.scheduled.length === 0) {
    keysToOmit.push('scheduled');
  }

  const apiAnnouncement: APIAnnouncementType = Transforms.toApiObject(
    Transforms.omitKeys({...announcement, organizations}, keysToOmit),
  );

  return apiAnnouncement;
};

export const buildUIRecipient = (
  state: string = '',
  organizations: NameIdType[] = [],
): RecipientType => {
  const hasState = state.length > 0;
  const hasOrg = organizations.length > 0;

  const recipient =
    !hasState && !hasOrg
      ? recipientTypes.allUsers
      : !hasOrg && hasState
      ? recipientTypes.byRegion
      : recipientTypes.byOrganization;

  const name = hasOrg
    ? organizations[0].name
    : hasState
    ? state
    : recipient.label;
  const id = hasOrg ? organizations[0].id : hasState ? state : recipient.value;

  return {id, type: recipient.value, name};
};

// @flow
import moment from 'moment';
import type {
  AnnouncementsDataGridType,
  FormAnnouncementType,
  UIAnnouncementType,
} from '../../types';
import {status} from '../../constants';

export const canHardDelete = (
  announcement: UIAnnouncementType | AnnouncementsDataGridType,
): boolean =>
  announcement.status === status.draft && !wasPublished(announcement);

export const isPublished = (
  announcement:
    | UIAnnouncementType
    | FormAnnouncementType
    | AnnouncementsDataGridType,
): boolean => announcement.status === status.published;

export const wasPublished = (
  announcement:
    | UIAnnouncementType
    | FormAnnouncementType
    | AnnouncementsDataGridType,
): boolean =>
  !!announcement.publishedOn && String(announcement.publishedOn).length > 0;

export const isDeleted = (
  announcement:
    | UIAnnouncementType
    | FormAnnouncementType
    | AnnouncementsDataGridType,
): boolean => announcement.status === status.deleted;

export const isDraft = (
  announcement:
    | UIAnnouncementType
    | FormAnnouncementType
    | AnnouncementsDataGridType,
): boolean => announcement.status === status.draft;

export const isScheduledForToday = (
  announcement: UIAnnouncementType | FormAnnouncementType,
): boolean => {
  return moment(announcement.scheduled).isSame(moment(), 'day');
};

// @flow
import React from 'react';
import type {Node} from 'react';
import {PageContainer} from 'components/PageContainer';
import {Toolbar} from 'components/Toolbar';
import {LayoutDrawer} from 'modules/app';

export const Root = (): Node => {
  return (
    <LayoutDrawer appBarChildren={<Toolbar />}>
      <PageContainer>
        <h1>{'Stories Home'}</h1>
      </PageContainer>
    </LayoutDrawer>
  );
};

// @flow
// $FlowFixMe
import {Transforms} from '@wellstone-solutions/common';
import type {APIContactType, UIContactType} from '../../types';
import {Factory} from './factories';

export const toUI = (contact: APIContactType): UIContactType => {
  return Factory(Transforms.toUIObject(contact));
};

export const toAPI = (contact: UIContactType): APIContactType =>
  Transforms.toApiObject(contact);

// @flow
import React from 'react';
import type {Node} from 'react';
import {PageContainer} from 'components/PageContainer';
import {Routes as SharedRoutes} from 'components/Routes';
import {Toolbar} from 'components/Toolbar';
import {LayoutDrawer} from 'modules/app';
import {routes} from './routes';
import {Toolbar as ResourcesToolbar} from './Toolbar';

export const Root = (): Node => {
  return (
    <LayoutDrawer
      appBarChildren={<Toolbar contentComponent={ResourcesToolbar} />}>
      <PageContainer>
        <SharedRoutes routes={routes} />
      </PageContainer>
    </LayoutDrawer>
  );
};

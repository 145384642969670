// @flow
import {routes} from 'api';
import {Api, Transforms} from '@wellstone-solutions/common';
import type {UIPhaseType} from '../../types';

export const getAll = async (): Promise<UIPhaseType[]> => {
  const response = await Api.Instance.current().get(routes.resources.phases);

  return response.data.phases.map((phase) => Transforms.toUIObject(phase));
};

// @flow
import {FormControl, FormLabel} from '@mui/material';
import {Typography, Button, Form} from '@wellstone-solutions/web';
import {Models, Hooks, Validation} from '@wellstone-solutions/common';
import {useStores} from 'hooks/useStores';
import {RadioGroup} from './RadioGroup';
import {useState, useEffect} from 'react';
import type {Node} from 'react';
import type {RoleType} from '@wellstone-solutions/common';

type RadioFormProps = {
  options: Array<{
    memberId: string,
    role: RoleType,
    userName: string,
    userEmail: string,
    organizationName: string,
  }>,
  resetSearch: () => void,
};

type Roles = {
  label: string,
  value: RoleType,
};

export const RadioForm = ({options, resetSearch}: RadioFormProps): Node => {
  const {alertStore} = useStores();
  const {isRequired} = Validation;
  const {useForm} = Hooks;
  const [roles, setRoles] = useState<Roles[]>([]);

  useEffect(() => {
    const getRoles = async () => {
      const response = await Models.Super.Member.getRoles();

      if (response.isSuccess) {
        const data = response.data.map((role: RoleType) => ({
          label: role,
          value: role,
        }));
        setRoles(data);
      }
    };
    getRoles();
  }, []);

  const form = useForm({
    initialValues: {
      memberId: '',
      role: '',
    },
    schema: {
      memberId: isRequired,
      role: isRequired,
    },
    onSubmit: async (values, {resetForm}) => {
      const response = await Models.Super.Member.updateRole(
        values.memberId,
        values.role,
      );

      if (!response.isSuccess) {
        alertStore.addError('Updating Role Failed!');
        return;
      }

      alertStore.addSuccess('Role Updated!');
      resetForm();
      resetSearch();
    },
  });

  if (!options || options?.length <= 0) {
    return null;
  }
  return (
    <Form onSubmit={form.handleSubmit}>
      <FormControl
        component="fieldset"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          '& > *': {
            marginTop: '1rem',
          },
        }}>
        <FormLabel>
          <Typography variant="subtitle1">Select a member:</Typography>
        </FormLabel>
        <RadioGroup
          field={{
            value: form.values,
            onChange: form.handleChange,
            setFieldValue: form.setFieldValue,
            setFieldTouched: form.setFieldTouched,
          }}
          options={options}
          roles={roles}
        />
        <Button
          sx={{
            marginTop: '1rem',
            alignSelf: 'flex-end',
          }}
          disabled={!form.dirty || !form.isValid || form.isSubmitting}
          variant="contained"
          type="submit">
          Update
        </Button>
      </FormControl>
    </Form>
  );
};

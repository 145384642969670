// @flow
import {
  FormControlLabel,
  Radio,
  RadioGroup as MUIRadioGroup,
} from '@mui/material';
import {Typography, Select} from '@wellstone-solutions/web';
import type {Node} from 'react';
import type { RoleType } from "@wellstone-solutions/common";

type Roles = {
  label: string,
  value: RoleType,
};

type RadioGroupProps = {
  field: {
    value: {
      memberId: string,
      role: string,
    },
    onChange: Function,
    setFieldValue: Function,
    setFieldTouched: Function,
  },
  options: Array<{
    memberId: string,
    role: RoleType,
    userName: string,
    userEmail: string,
    organizationName: string,
  }>,
  roles: Roles[],
  label?: string,
};

export const RadioGroup = ({field, options, roles}: RadioGroupProps): Node => {
  const {value, onChange, setFieldValue, setFieldTouched} = field;
  return (
    <MUIRadioGroup
      sx={{
        maxHeight: '20rem',
        overflowY: 'auto',
        flexWrap: 'nowrap',
      }}
      onChange={(event) => {
        onChange(event);
        setFieldValue('memberId', event.target.value);
        const role = options.find(
          (option) => option.memberId === event.target.value,
        )?.role;
        setFieldValue('role', role);
      }}>
      {options.map((member) => (
        <div key={member.memberId}>
          <FormControlLabel
            value={member.memberId}
            control={<Radio checked={value?.memberId === member.memberId} />}
            label={
              <div
                style={{
                  margin: '0.5rem',
                }}>
                <Typography variant="subtitle1">
                  <div>{member.userName}</div>
                </Typography>
                <div>
                  {member.userEmail} ({member.role} @ {member.organizationName})
                </div>
              </div>
            }
          />
          {value?.memberId === member.memberId && (
            <Select
              id="role"
              label="role"
              items={roles}
              value={value.role}
              onChange={(evt) => {
                setFieldTouched('role');
                setFieldValue('role', evt.target.value);
              }}
            />
          )}
        </div>
      ))}
    </MUIRadioGroup>
  );
};

// @flow
import React from 'react';
import type {ComponentType, Node} from 'react';
import {styled} from '@mui/material/styles';
import {
  AppBar as MuiAppBar,
  Button,
  Icon,
  IconNames,
  Toolbar,
  Typography,
  // $FlowFixMe
} from '@wellstone-solutions/web';
import {DRAWER_WIDTH} from './constants';

type PropsType = {
  open: boolean,
  toggleDrawer: () => mixed,
  children?: Node,
};

const StyledToolbar = styled(Toolbar)(() => ({
  paddingLeft: '0 !important',
}));

const AppBarWrapper = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({theme, open}) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: DRAWER_WIDTH,
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const AppBar: ComponentType<PropsType> = ({
  open,
  toggleDrawer,
  children,
}: PropsType): Node => (
  <AppBarWrapper open={open}>
    {children ? (
      children
    ) : (
      <StyledToolbar>
        <Button
          edge="start"
          color="inherit"
          aria-label="open drawer"
          data-testid="icon-menu"
          onClick={toggleDrawer}
          sx={{
            mr: 4,
            ...(open && {display: 'none'}),
          }}>
          <Icon name={IconNames.Menu} color="#FFF" />
        </Button>
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          sx={{flexGrow: 1, ...(open && {pl: 4})}}>
          Navigator
        </Typography>
      </StyledToolbar>
    )}
  </AppBarWrapper>
);

// @flow
import React, {useCallback} from 'react';
import type {ComponentType, Node} from 'react';
import {observer} from 'mobx-react';
import {useStores} from 'hooks/useStores';
import {ToolbarSelect} from 'components/ToolbarSelect';
import {recipientTypes} from '../../constants';

type PropsType = {
  onChange: (mixed) => void,
};

export const RecipientsSelect: ComponentType<PropsType> = observer(
  ({onChange}): Node => {
    const {announcementStore} = useStores();

    const handleChange = useCallback(
      (event) => {
        onChange(() => {
          announcementStore.setRecipient(event.target.value);
          announcementStore.getAnnouncements();
        });
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [onChange],
    );

    const renderValue = useCallback(
      (selected) => {
        const selectedItem = announcementStore.recipients.find(
          (d) => d.value === selected,
        );

        return selectedItem?.label;
      },
      [announcementStore.recipients],
    );

    const isVisible =
      announcementStore.recipientType !== recipientTypes.allUsers.value;

    if (!isVisible) {
      return null;
    }

    return (
      <ToolbarSelect
        canUnselect={false}
        containerProps={{
          fullWidth: true,
          sx: {maxWidth: 300},
        }}
        renderValue={renderValue}
        items={announcementStore.recipients}
        value={announcementStore.recipient}
        onChange={handleChange}
      />
    );
  },
);

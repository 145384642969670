// @flow
import React, {useCallback, useEffect} from 'react';
import type {ComponentType, Node} from 'react';
import {
  Stack,
  // $FlowFixMe
} from '@wellstone-solutions/web';
import {observer} from 'mobx-react';
import {useStores} from 'hooks/useStores';
import {domains, DomainSelect} from 'modules/contentManagement';
import {CategorySelect} from '../CategorySelect';
import {LocationSelect} from '../LocationSelect';

type PropsType = {
  redirectUser: (mixed, mixed) => void,
};

export const Toolbar: ComponentType<PropsType> = observer(
  ({redirectUser}): Node => {
    const {contentManagementUIStore} = useStores();
    // Set domain in CM to resources
    useEffect(() => {
      contentManagementUIStore.setDomain(domains.resources.value);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChange = useCallback(
      (onConfirmed) => {
        redirectUser(contentManagementUIStore.getBaseUrl, onConfirmed);
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [contentManagementUIStore.getBaseUrl],
    );

    return (
      <Stack
        direction="row"
        flex={1}
        justifyContent="flex-start"
        alignItems="center"
        spacing={4}>
        <DomainSelect onChange={handleChange} />
        {contentManagementUIStore.domain !== domains.answers.value && (
          <LocationSelect onChange={handleChange} />
        )}
        <CategorySelect onChange={handleChange} />
      </Stack>
    );
  },
);

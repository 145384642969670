// @flow
// $FlowFixMe
import {Validation} from '@wellstone-solutions/common';

const {isRequired} = Validation;

export const schema = {
  id: isRequired,
  name: isRequired,
};

export const formSchema = {
  id: isRequired,
};

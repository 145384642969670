// @flow
import * as api from './api';
import * as factories from './factories';
import * as selectors from './selectors';
import * as transforms from './transforms';
import * as validation from './validation';

export const AnnouncementModel = {
  ...api,
  ...factories,
  ...selectors,
  ...transforms,
  ...validation,
};

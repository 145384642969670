// @flow
// $FlowFixMe
import {Transforms} from '@wellstone-solutions/common';
import type {APIMeType, UIMeType} from '../../types';
import {Factory} from './factories';

export const toUI = (model: APIMeType): UIMeType => {
  const {memberships, ...rest} = model;

  return Factory(
    Transforms.toUIObject({
      ...rest,
      memberships: memberships.map(Transforms.toUIObject),
    }),
  );
};

export const toAPI = (model: UIMeType): APIMeType => {
  const {memberships, ...rest} = model;

  return Transforms.toApiObject({
    ...rest,
    memberships: memberships.map(Transforms.toApiObject),
  });
};

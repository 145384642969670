// @flow
import React from 'react';
import type {Node} from 'react';
import {useNavigate} from 'react-router-dom';
// $FlowFixMe
import {Button, Icon, IconNames} from '@wellstone-solutions/web';

type PropsType = {
  path: string | number,
};

export const BackButton = ({path}: PropsType): Node => {
  const navigate = useNavigate();

  return (
    <Button onClick={() => navigate(path)}>
      <Icon name={IconNames.ArrowLeftCircle} />
    </Button>
  );
};

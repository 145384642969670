// @flow
import React from 'react';
import type {Node} from 'react';
import {Link} from 'react-router-dom';
// $FlowFixMe
import {Box} from '@wellstone-solutions/web';

export const NotFound = (): Node => (
  <Box
    sx={{
      fontSize: 30,
      padding: 10,
      textAlign: 'center',
    }}>
    <div>Path not found! 🤷🏼‍♂️</div>
    <Box sx={{fontSize: 20, mt: 6}}>
      <Link to="/">Go home</Link>
    </Box>
  </Box>
);

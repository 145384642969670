// @flow
import React from 'react';
import type {Node} from 'react';
import {PageContainer} from 'components/PageContainer';
import {LayoutDrawer} from 'modules/app';

export const Root = (): Node => {
  return (
    <LayoutDrawer>
      <PageContainer>Reporting Home</PageContainer>
    </LayoutDrawer>
  );
};

// @flow
import React from 'react';
import type {ComponentType, Node} from 'react';
import {observer} from 'mobx-react';
import {useParams, useNavigate} from 'react-router-dom';
import {useStores} from 'hooks/useStores';
import {
  Paper,
  Stack,
  Typography,
  // $FlowFixMe
} from '@wellstone-solutions/web';
import {BackButton} from 'components/BackButton';
import {IconButton} from 'components/IconButton';
import {QuestionModel} from '../../models/question';
import {AnswersGrid} from './AnswersGrid';
import {Form} from './Form';

export const Detail: ComponentType<{}> = observer((): Node => {
  const params = useParams();
  const navigate = useNavigate();
  const {resourceStore} = useStores();
  const question = resourceStore.questions.find((q) => q.id === params.id);

  return (
    <>
      <Stack sx={{mb: 2}} direction="row">
        <BackButton path="../../questions" />
      </Stack>
      <Stack sx={{mt: 4, mb: 1}}>
        <Form
          question={QuestionModel.toForm(question)}
          onSave={(question) => resourceStore.updateQuestion(question)}
        />
        <Paper sx={{mt: 8, p: 2}}>
          <Typography variant="h5">Answers</Typography>
          <AnswersGrid questionId={question.id} />
        </Paper>
      </Stack>
      <IconButton
        sx={{mt: 2}}
        onClick={() =>
          navigate(`../../answers/new?question=${String(question?.id)}`)
        }>
        New Answer
      </IconButton>
    </>
  );
});

// @flow
import {useCallback, useState} from 'react';
import type {ComponentType, Node} from 'react';
import {observer} from 'mobx-react';
import {Link, useNavigate} from 'react-router-dom';
import type {GridColDef} from '@mui/x-data-grid';
import {
  DataGrid,
  Paper,
  Stack,
  Storage,
  Switch,
  Typography,
  // $FlowFixMe
} from '@wellstone-solutions/web';
import {useStores} from 'hooks/useStores';
import {IconButton} from 'components/IconButton';
import {PublishedStatusChip} from 'components/PublishedStatusChip';
import {formatDate} from 'utils/date';
import {Calendar} from './Calendar';
import {CancelActionButton} from './CancelActionButton';

const VIEW_KEY = 'announcements-list-view-key';

export const List: ComponentType<{}> = observer((): Node => {
  const [isCalendar, setIsCalendarView] = useState(
    Storage.getItem(VIEW_KEY) === 'true',
  );
  const {announcementStore, announcementUIStore} = useStores();
  const navigate = useNavigate();

  const onCancelAction = useCallback(
    (id, confirmMessage) => {
      const announcement = announcementStore.announcements.find(
        (a) => a.id === id,
      );
      const isConfirmed = window.confirm(confirmMessage);

      if (announcement && isConfirmed) {
        announcementStore.cancelAnnouncement(announcement);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [announcementStore.announcements],
  );

  const columns: GridColDef[] = [
    {field: 'id', hide: true, headerName: ''},
    {
      field: 'scheduled',
      headerName: 'Scheduled for',
      width: 150,
      renderCell: (params) => formatDate(params.value) || 'Not set',
    },
    {
      field: 'publishedOn',
      headerName: 'Publish Date',
      width: 150,
      renderCell: (params) => formatDate(params.value) || 'Not set',
    },
    {
      field: 'title',
      headerName: 'Title',
      flex: 1,
      renderCell: (params) => (
        <Link to={params.id}>{params.value || 'No title'}</Link>
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      renderCell: ({formattedValue}) => {
        return <PublishedStatusChip status={formattedValue.toLowerCase()} />;
      },
      width: 150,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      renderCell: (params) => (
        <CancelActionButton row={params.row} onCancel={onCancelAction} />
      ),
      width: 200,
    },
  ];

  return (
    <>
      <Paper
        sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          height: 'auto',
        }}>
        <Stack direction={'row'} sx={{justifyContent: 'space-between', mb: 4}}>
          <Typography variant="h5" sx={{mb: 4}}>
            Announcements
            <Switch
              defaultChecked={isCalendar}
              label="Calendar View"
              onChange={(event) => {
                setIsCalendarView(event.target.checked);
                Storage.setItem(VIEW_KEY, event.target.checked);
              }}
              sx={{
                ml: 2,
              }}
            />
          </Typography>

          <IconButton
            onClick={() => navigate('new')}
            sx={{
              alignSelf: 'flex-start',
            }}>
            New Announcement
          </IconButton>
        </Stack>
        {!isCalendar ? (
          <DataGrid
            autoHeight
            isFilterable
            isPersisted
            loading={announcementUIStore.isLoading}
            rows={announcementStore.dataGridAnnouncements}
            columns={columns}
            initialColumnFilter="title"
          />
        ) : (
          <Calendar
            events={announcementStore.calendarAnnouncements}
            onSelectEvent={(event) => {
              navigate(event.resource.id);
            }}
          />
        )}
      </Paper>
    </>
  );
});

// @flow
import {action, makeObservable, observable} from 'mobx';
// $FlowFixMe
import {Storage} from '@wellstone-solutions/web';
import {RootStore} from 'RootStore';

export class AppUIStore {
  rootStore: RootStore | null = null;
  isDirty: boolean = false;

  sideDrawerIsOpen: boolean =
    Storage.getItem('sideDrawerIsOpen') === null
      ? true
      : Storage.getItem('sideDrawerIsOpen') === 'true';

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      isDirty: observable,
      sideDrawerIsOpen: observable,
      setIsDirty: action,
      toggleSideDrawer: action,
    });

    this.rootStore = rootStore;
  }

  setIsDirty(update: boolean): void {
    this.isDirty = update;
  }

  toggleSideDrawer(): void {
    Storage.setItem('sideDrawerIsOpen', (!this.sideDrawerIsOpen).toString());

    this.sideDrawerIsOpen = !this.sideDrawerIsOpen;
  }
}

// @flow
// $FlowFixMe
import {Storage} from '@wellstone-solutions/web';

const APP_CRYPTO_KEY = process.env.REACT_APP_CRYPTO_KEY;

export const storage: {
  getItem: (string) => string | null,
  removeItem: (string) => mixed,
  setItem: (string, mixed) => mixed,
} = {
  getItem: (key) => Storage.getItem(key, APP_CRYPTO_KEY),
  removeItem: (key) => Storage.removeItem(key),
  setItem: (key, value) => Storage.setItem(key, value, APP_CRYPTO_KEY),
};

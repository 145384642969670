// @flow
import {action, makeObservable, observable} from 'mobx';
import {storage} from 'utils/storage';
import {RootStore} from 'RootStore';
import {locations} from 'modules/app';
import {categories, storageKeys} from './constants';

export class ResourcesUIStore {
  location: string =
    storage.getItem(storageKeys.location) || locations[0].value;
  category: string =
    storage.getItem(storageKeys.category) || categories[0].value;
  rootStore: RootStore | null = null;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      location: observable,
      category: observable,
      setLocation: action,
      setCategory: action,
    });

    this.rootStore = rootStore;
  }

  setLocation(location: string): void {
    this.location = location;
    storage.setItem(storageKeys.location, location);
  }

  setCategory(category: string): void {
    this.category = category;
    storage.setItem(storageKeys.category, category);
  }
}

// @flow
import React, {useCallback} from 'react';
import type {ComponentType, Node} from 'react';
import {useNavigate} from 'react-router-dom';
import {observer} from 'mobx-react';
// $FlowFixMe
import {Theme} from '@wellstone-solutions/common';
import {
  Button,
  Icon,
  IconNames,
  Toolbar as CommonToolbar,
  // $FlowFixMe
} from '@wellstone-solutions/web';
import {useStores} from 'hooks/useStores';

type PropTypes = {
  contentComponent?: Node,
};

export const Toolbar: ComponentType<PropTypes> = observer(
  ({contentComponent: ContentComponent}): Node => {
    const {appUIStore} = useStores();
    const navigate = useNavigate();
    const open = appUIStore.sideDrawerIsOpen;

    const toggleDrawer = useCallback(() => {
      appUIStore.toggleSideDrawer();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const redirectUser = useCallback(
      (getUrl, onConfirmed) => {
        const isConfirmed = appUIStore.isDirty
          ? window.confirm(
              'There are pending changes, are you sure you want to leave?',
            )
          : true;

        if (!isConfirmed) {
          return;
        }

        onConfirmed();
        appUIStore.setIsDirty(false);
        const url = getUrl();
        navigate(url, {replace: true});
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [appUIStore.isDirty],
    );

    return (
      <CommonToolbar disableGutters>
        <Button
          edge="start"
          color="inherit"
          aria-label="open drawer"
          data-testid="icon-menu"
          onClick={toggleDrawer}
          sx={{
            mr: 4,
            ...(open && {display: 'none'}),
          }}>
          <Icon name={IconNames.Menu} color={Theme.colorPalette.lightest} />
        </Button>
        {ContentComponent
          ? React.createElement(ContentComponent, {redirectUser})
          : null}
      </CommonToolbar>
    );
  },
);

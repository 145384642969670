// @flow
import React from 'react';
import type {Node} from 'react';
import {Routes as RouterRoutes, Route} from 'react-router-dom';
import {RouteElement} from 'components/RouteElement';
import type {RouteType} from 'types';

type PropsType = {
  routes: RouteType[],
};

export const Routes = ({routes}: PropsType): Node => (
  <RouterRoutes>
    {routes.map((route, routeIndex) => {
      const {path, index, Component, isProtected, ...rest} = route;

      return (
        <Route
          {...rest}
          path={path ? path : null}
          index={index ? index : null}
          key={routeIndex}
          element={
            <RouteElement isProtected={isProtected}>
              {/* $FlowIgnoreMe */}
              <Component />
            </RouteElement>
          }
        />
      );
    })}
  </RouterRoutes>
);

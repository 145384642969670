import type {Node} from 'react';
import {Login, SubmitCode} from 'modules/auth';
import {Routes} from 'components/Routes';
import {NotFound} from 'components/NotFound';
import {contentManagement} from './contentManagement';
import {customerService} from './customerService';
import {dashboard} from './dashboard';
import {reporting} from './reporting';

export const routes = [
  {
    Component: Login,
    isProtected: false,
    path: '/login',
  },
  {
    Component: SubmitCode,
    isProtected: false,
    path: '/submit-code',
  },
  {
    Component: (): Node => <Routes routes={dashboard} />,
    isProtected: true,
    path: '/*',
  },
  {
    Component: (): Node => <Routes routes={contentManagement} />,
    isProtected: true,
    path: '/content-management/*',
  },
  {
    Component: (): Node => <Routes routes={customerService} />,
    isProtected: true,
    path: '/customer-service/*',
  },
  {
    Component: (): Node => <Routes routes={reporting} />,
    isProtected: true,
    path: '/reporting/*',
  },
  {
    Component: NotFound,
    isProtected: false,
    path: '*',
  },
];

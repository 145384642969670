// @flow
import React from 'react';
import type {Node} from 'react';
import {Navigate} from 'react-router-dom';
import {useStores} from 'hooks/useStores';

type PropsType = {
  children: Node,
  isProtected?: boolean,
  routes?: mixed,
  ...
};

export const RouteElement = ({
  children,
  isProtected = false,
}: PropsType): Node => {
  const {authStore} = useStores();

  if (!isProtected) return children;

  return authStore.isAuthenticated ? children : <Navigate to="/login" />;
};

// @flow
import type {ComponentType, Node} from 'react';
import {Hooks} from '@wellstone-solutions/common';
import {
  Box,
  Button,
  Form,
  FormRow,
  Select,
  Typography,
} from '@wellstone-solutions/web';
import {useStores} from 'hooks/useStores';
import {observer} from 'mobx-react';
import {FLAG_STRATEGIES} from '../../constants';
import type {flagOwnersOptionsType} from '../../types';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

const {useForm} = Hooks;

export type AddFlagPropsType = {
  handleClose: () => void,
};

export const AddFlag: ComponentType<AddFlagPropsType> = observer(
  ({handleClose}: AddFlagPropsType): Node => {
    const {alertStore, customerServiceStore} = useStores();
    const {featureFlags, users, organizations, programs} = customerServiceStore;
    const initialValues = {
      selectedFlagId: '',
      flagOwnersOptions: [],
      ownerId: '',
      strategy: '',
    };

    const handleSubmit = async () => {
      if (!form.isValid) {
        alertStore.addWarning('Please fill out all required fields!');
        return;
      }
      const res = await customerServiceStore.addFlagOwner(form.values);
      if (!res.isSuccess) {
        alertStore.addError('Something went wrong');
        return;
      }

      alertStore.addSuccess('Added Flag Owner');
      form.resetForm(initialValues);
      handleClose();
    };

    const form = useForm({
      initialValues,
      onSubmit: handleSubmit,
      schema: false,
    });

    const handleFlagChange = (e) => {
      const selectedFlagId = e.target.value;
      const flagOwnersOptions = [];

      const selectedFlagObj = featureFlags?.find(
        (flag) => flag.id === selectedFlagId,
      );

      if (selectedFlagObj) {
        const strategy: string = selectedFlagObj?.strategy;

        if (strategy === FLAG_STRATEGIES.selectUsers) {
          flagOwnersOptions.push(...users);
        } else if (strategy === FLAG_STRATEGIES.selectOrg) {
          flagOwnersOptions.push(...organizations);
        } else if (strategy === FLAG_STRATEGIES.selectProgram) {
          flagOwnersOptions.push(...programs);
        }
      }

      form.setValues({
        ...form.values,
        selectedFlagId,
        flagOwnersOptions,
        strategy: selectedFlagObj?.strategy,
        ownerId: '',
      });
    };

    return (
      <Box>
        <Typography variant="subtitle1">Add Flag</Typography>
        <Form form={form} onSubmit={form.onSubmit}>
          <FormRow>
            <Select
              containerProps={{
                fullWidth: true,
              }}
              label="Flag"
              items={featureFlags?.map((flag) => ({
                label: flag.name,
                value: flag.id,
              }))}
              onChange={handleFlagChange}
              value={form.values.selectedFlagId}
              data-testid="flag-select"
            />
          </FormRow>

          {form.values.selectedFlagId && form.values.strategy && (
            <FormRow>
              <Autocomplete
                fullWidth
                options={form.values.flagOwnersOptions}
                getOptionLabel={
                  form.values.strategy === 'select_program'
                    ? (option) => option?.name
                    : (option) => renderFlagOwnerLabelOption(option)
                }
                groupBy={
                  form.values.strategy === 'select_program'
                    ? (option) => option?.organization?.name
                    : undefined
                }
                onChange={(e, newValue) => {
                  form.setValues({
                    ...form.values,
                    ownerId: newValue ? newValue.id : '',
                  });
                }}
                value={
                  form.values.flagOwnersOptions.find(
                    (option) => option.id === form.values.ownerId,
                  ) || null
                }
                renderInput={(params) => (
                  <TextField {...params} label={'Flag Owner'} />
                )}
              />
            </FormRow>
          )}
          <FormRow>
            <div>
              <Button variant="text" onClick={handleSubmit}>
                Add Flag
              </Button>
              <Button onClick={handleClose} color="error" variant="text">
                Cancel
              </Button>
            </div>
          </FormRow>
        </Form>
      </Box>
    );
  },
);

const renderFlagOwnerLabelOption = (option: flagOwnersOptionsType) => {
  if (option?.email) {
    return `${option.name} (${option?.email})`;
  }

  if (option?.organization) {
    return `${option.name} (${option?.organization.name})`;
  }

  return `${option.name}`;
};

// @flow
import React, {useEffect} from 'react';
import type {ComponentType, Node} from 'react';
import {useNavigate} from 'react-router-dom';
import {observer} from 'mobx-react';
import {styled} from '@mui/material/styles';
import {
  Button,
  ErrorMessage,
  FormRow,
  Typography,
  TextField,
  Paper,
  // $FlowFixMe
} from '@wellstone-solutions/web';
// $FlowFixMe
import {Hooks, Validation} from '@wellstone-solutions/common';
import {useStores} from 'hooks/useStores';
import {LayoutBasic} from 'modules/app';

const Form = styled('form')(({theme}) => ({
  marginTop: theme.spacing(4),
  width: '100%',
}));

const CODE_LENGTH = 6;
const isValidCode = Validation.isLength(CODE_LENGTH);

const validateCode = (code) => Validation.isRequired(code) && isValidCode(code);

export const SubmitCode: ComponentType<{}> = observer((): Node => {
  const navigate = useNavigate();
  const {alertStore, authStore} = useStores();

  useEffect(() => {
    if (!authStore.hasEmail) {
      navigate('/login', {
        replace: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authStore.hasEmail]);

  useEffect(() => {
    if (authStore.isAuthenticated) {
      navigate('/', {
        replace: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authStore.isAuthenticated]);

  const schema = {
    code: {
      validate: validateCode,
      error: `Code must be ${CODE_LENGTH} characters`,
    },
  };

  const initialValues = {
    code: '',
  };

  const handleSendNewCode = () => {
    alertStore.addInfo('New code sent!');
    authStore.sendNewCode();
  };

  const form = Hooks.useForm({
    initialValues,
    schema,
    onSubmit: async ({code}) => {
      const response = await authStore.signIn(code);

      if (!response.isSuccess) {
        alertStore.addError('Invalid code');
      }
    },
  });

  return (
    <LayoutBasic>
      <Paper
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          maxWidth: {
            xs: '100%',
            sm: 600,
          },
          minWidth: 500,
          padding: 4,
        }}>
        <Typography component="h1" variant="h5">
          Login
        </Typography>
        <Form onSubmit={form.handleSubmit}>
          <FormRow>
            <TextField
              autoFocus
              fullWidth
              formField="code"
              onChange={form.handleFieldChange('code')}
              value={form.values.code}
              label="Code"
            />
            <ErrorMessage
              name="code"
              errors={form.errors}
              touched={form.touched}
            />
          </FormRow>
          <FormRow direction="row" justifyContent="space-between">
            <Button color="primary" variant="contained" fullWidth type="submit">
              Submit Code
            </Button>
            <Button
              color="secondary"
              onClick={handleSendNewCode}
              variant="outlined"
              fullWidth
              sx={{ml: 4}}>
              Send a new Code
            </Button>
          </FormRow>
        </Form>
      </Paper>
    </LayoutBasic>
  );
});

// @flow
// $FlowFixMe
import {Validation} from '@wellstone-solutions/common';
import {schema as contactSchema} from '../contact/validation';

const {isOptional, isRequired} = Validation;

export const schema = {
  id: isOptional,
  title: isRequired,
  subtitle: isOptional,
  description: isOptional,
  organizationTitle: isOptional,
  contacts: {schema: contactSchema},
  other: isOptional,
  state: (value: string): boolean => value === '' || value?.length > 0,
  modified: isOptional,
  expires: isRequired,
  question: (value: {id: string}): boolean => Boolean(value && value.id),
};

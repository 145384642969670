// @flow
import React, {useEffect} from 'react';
import type {ComponentType, Node} from 'react';
import {observer} from 'mobx-react';
import {useStores} from 'hooks/useStores';
import {AlertManager} from 'modules/alerts';
import {Loading} from 'components/Loading';

type PropsType = {
  children: Node,
};

export const AppContainer: ComponentType<PropsType> = observer(
  ({children}: PropsType): Node => {
    const rootStore = useStores();
    const {authStore} = rootStore;

    useEffect(() => {
      rootStore.initApi();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      rootStore.initStores();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authStore.isAuthenticated]);

    return rootStore.isReady ? (
      <>
        <AlertManager />
        {children}
      </>
    ) : (
      <Loading />
    );
  },
);

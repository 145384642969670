// @flow
import type {Node} from 'react';
// $FlowFixMe
import {Box, Progress, ProgressTypes} from '@wellstone-solutions/web';
// $FlowFixMe
import {Theme} from '@wellstone-solutions/common';

export const Loading = (): Node => (
  <Box
    sx={{
      alignItems: 'flex-start',
      backgroundColor: Theme.colorPalette.light,
      display: 'flex',
      justifyContent: 'center',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    }}>
    <Progress
      color="secondary"
      progressType={ProgressTypes.linear}
      sx={{width: '100%'}}
    />
  </Box>
);

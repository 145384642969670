// @flow
import type {Node} from 'react';
import {Calendar as BigCalendar, momentLocalizer} from 'react-big-calendar';
import moment from 'moment';
// $FlowIgnoreMe
import 'react-big-calendar/lib/css/react-big-calendar.css';
import type {AnnouncementsCalendarType} from '../../types';

const localizer = momentLocalizer(moment);

type PropsType = {
  events: Array<AnnouncementsCalendarType>,
  onSelectEvent: (AnnouncementsCalendarType) => void,
};

export const Calendar = ({events, onSelectEvent}: PropsType): Node => (
  <BigCalendar
    localizer={localizer}
    events={events}
    startAccessor="start"
    endAccessor="end"
    onSelectEvent={onSelectEvent}
    style={{height: '100vh'}}
  />
);

// @flow
import React, {useCallback, useEffect} from 'react';
import type {Node} from 'react';
import {
  Stack,
  // $FlowFixMe
} from '@wellstone-solutions/web';
import {useStores} from 'hooks/useStores';
import {domains, DomainSelect} from 'modules/contentManagement';
import {RecipientTypeSelect} from '../RecipientTypeSelect';
import {RecipientsSelect} from '../RecipientsSelect';

type PropsType = {
  redirectUser: (mixed, mixed) => void,
};

export const Toolbar = ({redirectUser}: PropsType): Node => {
  const {contentManagementUIStore} = useStores();

  // Set domain in CM to announcements
  useEffect(() => {
    contentManagementUIStore.setDomain(domains.announcements.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = useCallback(
    (onConfirmed) => {
      redirectUser(contentManagementUIStore.getBaseUrl, onConfirmed);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contentManagementUIStore.getBaseUrl],
  );

  return (
    <Stack
      direction="row"
      flex={1}
      justifyContent="flex-start"
      alignItems="center"
      spacing={4}>
      <DomainSelect onChange={handleChange} />
      <RecipientTypeSelect onChange={handleChange} />
      <RecipientsSelect onChange={handleChange} />
    </Stack>
  );
};

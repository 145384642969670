// @flow
// $FlowFixMe
import {Transforms} from '@wellstone-solutions/common';
import type {IObservableArray} from 'mobx';
import {UNIVERSAL_LOCATION} from 'modules/app';
import {ContactModel} from '../contact';
import type {
  APIAnswerType,
  UIAnswerType,
  UIResourceQuestionType,
  AnswersDataGridType,
} from '../../types';
import {Factory} from './factories';

export const toUI = (
  answer: APIAnswerType,
  questions: IObservableArray<UIResourceQuestionType>,
): UIAnswerType => {
  const {question_id, data, state, ...rest} = answer;
  const {contacts = [], ...otherData} = data;
  const question = Transforms.hydrate({id: question_id}, questions);

  const uiAnswer = {
    ...Transforms.toUIObject(rest),
    contacts: contacts.map(ContactModel.toUI),
    other: otherData,
    state: state === '' ? UNIVERSAL_LOCATION : state,
    question,
  };

  return Factory(uiAnswer);
};

export const toAPI = (answer: UIAnswerType): APIAnswerType => {
  const keysToOmit = ['modified'];
  if (answer.id?.length === 0) {
    keysToOmit.push('id');
  }

  const {contacts, expires, other, question, state, ...rest} =
    Transforms.omitKeys(answer, keysToOmit);

  const apiAnswer = {
    ...Transforms.toApiObject(rest),
    expires: new Date(expires).toISOString(),
    data: {contacts: contacts.map(ContactModel.toAPI), ...other},
    state: state === UNIVERSAL_LOCATION ? '' : state,
    question_id: question.id,
  };

  return apiAnswer;
};

export const toDataGrid = (answer: UIAnswerType): AnswersDataGridType => {
  // Concat contact fields into string for filtering
  const contactSearchFields = [
    'title',
    'website',
    'websiteLabel',
    'phoneNumber',
  ];
  const contactSearch = answer.contacts
    .map((contact) => {
      const parts = [];
      for (const field of contactSearchFields) {
        if (contact[field]) {
          parts.push(contact[field]);
        }
      }
      return parts.join(' ');
    })
    .join(' ');

  const {id, organizationTitle, title, subtitle, modified, expires} = answer;

  return {
    id,
    organizationTitle,
    title,
    subtitle,
    modified,
    expires,
    contactSearch,
  };
};

//@flow
import {action, makeObservable, observable} from 'mobx';
import {AlertStore} from 'modules/alerts/store';
import {AnnouncementStore} from './modules/announcements/store';
import {AnnouncementUIStore} from './modules/announcements/ui-store';
import {AuthStore} from 'modules/auth/store';
import {ContentManagementUIStore} from 'modules/contentManagement/ui-store';
import {DashboardStore} from 'modules/dashboard/store';
import {AppUIStore} from 'modules/app/ui-store';
import {ResourceStore} from 'modules/resources/store';
import {ResourcesUIStore} from 'modules/resources/ui-store';
import {CustomerServiceStore} from 'modules/customerService/store';
import {Api} from '@wellstone-solutions/common';

const STORE_SUFFIX = 'Store';
const STORE_BLACKLIST = ['AuthStore'];

export class RootStore {
  isReady: boolean = false;
  authStore: AuthStore = new AuthStore(this);
  announcementStore: AnnouncementStore = new AnnouncementStore(this);
  announcementUIStore: AnnouncementUIStore = new AnnouncementUIStore(this);
  alertStore: AlertStore = new AlertStore(this);
  contentManagementUIStore: ContentManagementUIStore =
    new ContentManagementUIStore(this);
  dashboardStore: DashboardStore = new DashboardStore(this);
  appUIStore: AppUIStore = new AppUIStore(this);
  resourceStore: ResourceStore = new ResourceStore(this);
  resourcesUIStore: ResourcesUIStore = new ResourcesUIStore(this);
  customerServiceStore: CustomerServiceStore = new CustomerServiceStore(this);

  constructor() {
    makeObservable(this, {
      done: action,
      isReady: observable,
    });
  }

  done(): void {
    this.isReady = true;
  }

  async initApi(): Promise<mixed> {
    Api.Instance.current().interceptors.response.use(
      (response) => {
        if (response.isSuccess) {
          return response;
        }

        // log me out if I'm getting 401 responses
        if (response.status === 401) {
          Api.Instance.current().abort();
          this.authStore.signOut();
          return response;
        }

        return response;
      },
      (error) => {
        if (error.status === 401) {
          this.authStore.signOut();
        } else {
          return Promise.reject(error);
        }
      },
    );

    if (this.authStore) {
      await this.authStore.init();
    }
  }

  async initStores(): Promise<mixed> {
    if (!this.authStore.isAuthenticated) {
      this.done();
      return;
    }

    try {
      const initStores = [];
      for (let prop in this) {
        if (!STORE_BLACKLIST.includes(prop) && prop.endsWith(STORE_SUFFIX)) {
          // $FlowIgnore[prop-missing]
          if (this[prop].init) {
            initStores.push(this[prop]);
          }
        }
      }

      // Initialize stores
      await Promise.all(
        initStores.map(async (store) => {
          await store.init();
        }),
      );
    } catch (error) {
      console.log('rootStore:error', error);
    } finally {
      this.done();
    }
  }
}

// @flow
// $FlowFixMe
import {Validation} from '@wellstone-solutions/common';
import {CategoryModel} from '../category';
import {PhaseModel} from '../phase';
const {isOptional, isRequired, hasSelected} = Validation;

const isEmptyOrHasSelected = (values: mixed[]): boolean =>
  values.length === 0 || hasSelected(values);

export const schema = {
  id: isOptional,
  title: isRequired,
  categories: {
    validate: hasSelected,
    schema: CategoryModel.schema,
  },
  modified: isOptional,
  phases: {
    validate: isEmptyOrHasSelected,
    schema: PhaseModel.schema,
  },
};

export const formSchema = {
  id: isOptional,
  title: isRequired,
  categories: {
    validate: hasSelected,
    schema: CategoryModel.formSchema,
  },
  modified: isOptional,
  phases: {
    validate: isEmptyOrHasSelected,
    schema: PhaseModel.formSchema,
  },
};

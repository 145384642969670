// @flow
import React, {useMemo} from 'react';
import type {ComponentType, Node} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {observer} from 'mobx-react';
// $FlowFixMe
import {
  Box,
  Button,
  DataGrid,
  Paper,
  Typography,
} from '@wellstone-solutions/web';
import {useStores} from 'hooks/useStores';
import {IconButton} from 'components/IconButton';
import {DATE_TIME} from 'constants/dateFormats';
import {formatDate} from 'utils/date';
// $FlowFixMe
import type {GridColDef} from '@mui/x-data-grid';
import {appLinks} from 'appLinks';

export const List: ComponentType<{}> = observer((): Node => {
  const navigate = useNavigate();
  const {resourceStore, resourcesUIStore} = useStores();
  const dataGridQuestions = useMemo(() => {
    return Array.from(resourceStore.dataGridQuestions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceStore.questions, resourceStore.answers]);

  const columns: GridColDef[] = [
    {field: 'id', hide: true, headerName: ''},
    {
      field: 'modified',
      headerName: 'Last Updated',
      flex: 1,
      renderCell: (params) => formatDate(params.value, DATE_TIME),
    },
    {
      field: 'title',
      headerName: 'Title',
      flex: 4,
      renderCell: (params) => (
        <Box sx={{whiteSpace: 'pre-wrap'}}>
          <Link to={params.id}>{params.value}</Link>
        </Box>
      ),
    },
    {
      field: 'categories',
      headerName: 'Categories',
      flex: 1,
      renderCell: (params) => (
        <Box sx={{whiteSpace: 'pre-wrap'}}>{params.value}</Box>
      ),
    },
    {
      field: 'hasAnswer',
      flex: 1,
      headerName: `Answered in ${resourcesUIStore.location}`,
    },
    {
      field: 'lastAnswerUpdate',
      flex: 1,
      headerName: `Last Answer Updated`,
      renderCell: (params) => formatDate(params.value, DATE_TIME),
    },
    {
      field: 'actions',
      flex: 1,
      headerName: `Actions`,
      renderCell: (params) => (
        <Button
          variant="outlined"
          onClick={() =>
            navigator.clipboard.writeText(appLinks.question(params.id))
          }
          color="primary"
          sx={{
            flex: 1,
            textTransform: 'none',
          }}>
          Copy Link
        </Button>
      ),
    },
  ];

  return (
    <>
      <Paper
        sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          height: 'auto',
        }}>
        <Typography variant="h5" sx={{mb: 4}}>
          Resource Questions
        </Typography>
        <DataGrid
          autoHeight
          isFilterable
          isPersisted
          rows={dataGridQuestions}
          columns={columns}
          initialColumnFilter="title"
        />
      </Paper>
      <IconButton sx={{mt: 4}} onClick={() => navigate('new')}>
        New Question
      </IconButton>
    </>
  );
});

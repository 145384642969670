// @flow
import type {Node} from 'react';
// $FlowFixMe
import {Chip} from '@wellstone-solutions/web';

type PropsType = {
  status: string,
};

const DEFAULT = 'Unknown';

const getStatus = (status) => {
  if (!status) {
    return DEFAULT;
  }

  if (status === 'deleted') {
    return 'canceled';
  }

  return status;
};

export const PublishedStatusChip = ({status}: PropsType): Node => {
  let color = 'primary';

  const safeStatus = getStatus(status);

  switch (safeStatus.toLowerCase()) {
    case 'active':
    case 'published':
      color = 'success';
      break;
    case 'canceled':
      color = 'error';
      break;

    default:
      color = 'info';
      break;
  }

  return (
    <Chip label={safeStatus.toUpperCase()} color={color} variant="filled" />
  );
};

// @flow
// $FlowFixMe
import {Api, Transforms} from '@wellstone-solutions/common';
import {routes} from 'api';
import type {UICategoryType} from '../../types';

export const getAll = async (): Promise<UICategoryType[]> => {
  const response = await Api.Instance.current().get(
    routes.resources.categories,
  );

  return response.data.groups.map((cat) => Transforms.toUIObject(cat));
};

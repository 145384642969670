// @flow
// $FlowIgnore
import {Factories} from '@wellstone-solutions/common';
import type {
  FactoryResourceQuestionType,
  FormResourceQuestionType,
  UIResourceQuestionType,
} from '../../types';

export const Factory = (
  overrides?: FactoryResourceQuestionType,
): UIResourceQuestionType =>
  Factories.createFactory(
    {
      id: '',
      title: '',
      categories: [],
      phases: [],
      modified: '',
    },
    overrides,
  );

export const FormFactory = (
  overrides?: FactoryResourceQuestionType,
): FormResourceQuestionType =>
  Factories.createFactory(
    {
      id: '',
      title: '',
      categories: [],
      phases: [],
      modified: '',
    },
    overrides,
  );

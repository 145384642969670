import * as api from './api';
import * as factories from './factories';
import * as schema from './validation';
import * as transforms from './transforms';

export const QuestionModel = {
  ...api,
  ...factories,
  ...schema,
  ...transforms,
};

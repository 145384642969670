// @flow
import * as React from 'react';
import type {ComponentType, Node} from 'react';
import {Link} from 'react-router-dom';
import {styled} from '@mui/material/styles';
import {
  Button,
  Divider,
  Drawer as MuiDrawer,
  Icon,
  IconNames,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  // $FlowFixMe
} from '@wellstone-solutions/web';
import {useStores} from 'hooks/useStores';
import {DRAWER_WIDTH} from '../constants';

type PropsType = {open: boolean, toggleDrawer: () => void};

const SideDrawerWrapper: ComponentType<{}> = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({theme, open}) => ({
  '& .MuiDrawer-paper': {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: DRAWER_WIDTH,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const mainNav = [
  {
    icon: IconNames.NavigatorAlt,
    name: 'Dashboard',
    path: '/',
  },
  {
    icon: IconNames.JournalPage,
    name: 'Content Management',
    path: '/content-management',
  },
  {
    icon: IconNames.Group,
    name: 'Customer Service',
    path: '/customer-service',
  },
  {
    icon: IconNames.StatsReport,
    name: 'Reporting',
    path: '/reporting',
  },
];

export const SideDrawer = ({open, toggleDrawer}: PropsType): Node => {
  const {authStore} = useStores();

  const handleSignOut = () => authStore.signOut();

  return (
    <SideDrawerWrapper variant="permanent" open={open}>
      <Toolbar
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          px: [1],
        }}>
        <Button data-testid="icon-arrow-left" onClick={toggleDrawer}>
          <Icon name={IconNames.ArrowLeft} />
        </Button>
      </Toolbar>
      <Divider />
      <List>
        {mainNav.map((item) => (
          <Link key={item.name} to={item.path}>
            <ListItem button sx={{justifyContent: 'center'}}>
              <ListItemIcon sx={{minWidth: 'unset', mr: open ? 2 : 0}}>
                <Icon name={item.icon} />
              </ListItemIcon>
              {open && (
                <ListItemText
                  primary={item.name}
                  sx={{
                    color: (theme) =>
                      theme.palette.mode === 'light'
                        ? theme.palette.grey[900]
                        : theme.palette.grey[100],
                  }}
                />
              )}
            </ListItem>
          </Link>
        ))}
      </List>
      <Divider />
      <List>
        <ListItem
          button
          onClick={handleSignOut}
          sx={{justifyContent: 'center'}}>
          <ListItemIcon sx={{minWidth: 'unset', mr: open ? 2 : 0}}>
            <Icon name={IconNames.LogOut} />
          </ListItemIcon>
          {open && <ListItemText primary="Sign Out" />}
        </ListItem>
      </List>
    </SideDrawerWrapper>
  );
};

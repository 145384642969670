// @flow
// $FlowIgnore
import {Factories} from '@wellstone-solutions/common';
import type {
  UIAnnouncementType,
  FactoryAnnouncementType,
  FormAnnouncementType,
} from '../../types';

export const Factory = (
  overrides?: FactoryAnnouncementType | UIAnnouncementType,
): UIAnnouncementType =>
  Factories.createFactory(
    {
      author: '',
      content: '',
      created: '',
      group: '',
      id: '',
      modified: '',
      recipient: {
        id: '',
        type: '',
        name: '',
      },
      organizations: [],
      publishedOn: '',
      scheduled: '',
      state: '',
      status: 'draft',
      title: '',
    },
    overrides,
  );

export const FormFactory = (
  overrides?: FormAnnouncementType,
): FormAnnouncementType =>
  Factories.createFactory(
    {
      author: '',
      content: '',
      id: '',
      scheduled: '',
      status: 'draft',
      title: '',
    },
    overrides,
  );

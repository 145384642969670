// @flow
import {Api} from '@wellstone-solutions/common';
import {routes} from 'api';
import type {OrganizationType} from '../../types';

export const getAll = async (): Promise<Array<OrganizationType>> => {
  const response = await Api.Instance.current().get(routes.orgs);

  return response.isSuccess ? response.data.organizations : [];
};

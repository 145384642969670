// @flow
import {action, makeObservable, observable} from 'mobx';
import {RootStore} from 'RootStore';

export class AnnouncementUIStore {
  rootStore: RootStore;
  isLoading: boolean = false;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      isLoading: observable,
      setIsLoading: action,
    });

    this.rootStore = rootStore;
  }

  setIsLoading(isLoading: boolean): void {
    this.isLoading = isLoading;
  }
}

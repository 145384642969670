// @flow
import moment from 'moment';
import {DATE} from 'constants/dateFormats';

export const formatDate = (
  date: string | Date,
  dateFormat: string = DATE,
): string => {
  if (!date || date.length === 0) {
    return '';
  }

  return moment(date).format(dateFormat);
};

// @flow
import type {LabelValueType} from 'types';

export const UNIVERSAL_LOCATION = 'universal';

export const categories: LabelValueType[] = [
  {label: 'All Questions', value: 'questions'},
];

export const storageKeys = {
  category: 'resources:category',
  location: 'resources:location',
};
